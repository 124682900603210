import { mainGraph } from '@pretto/app/src/Sentences/config/routes/mainGraph'
import { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { createRoutesGraphContext } from '@pretto/app/src/lib/graph/RoutesGraphContext'

import { useSentences } from './SentencesContext'

const { Provider, useRoutesGraph } = createRoutesGraphContext<ProjectContext>(mainGraph)

export { useRoutesGraph }

export const RoutesGraphContextProvider: React.FC = ({ children }) => {
  const projectContext = useSentences()

  return (
    <Provider context={projectContext} wait={projectContext.waitForOngoingOperations}>
      {children}
    </Provider>
  )
}
