import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { SimulationType, SituationType, Usage } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const SituationPage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors, simulationType, usage, ownPropertyForSale: forSale } = context
  const { situationType: situation } = mortgagors?.[0] ?? {}
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const hasRentToPay = situation === SituationType.Tenant || situation === SituationType.StaffHoused
  const maySaleProperty =
    (simulationType === SimulationType.Capacity || simulationType === SimulationType.Purchase) &&
    usage === Usage.Primary &&
    situation === SituationType.Owner
  const staffHousedRentIsZero = situation === SituationType.StaffHoused && mortgagors?.[0].rent === 0

  const getDefaultActiveField = () =>
    (hasRentToPay && !!mortgagors?.[0].rent) ||
    (maySaleProperty && !isNil(forSale)) ||
    situation === SituationType.Sheltered
      ? 1
      : 0

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (!mortgagors?.[0].situationType) return false
    if (hasRentToPay && !mortgagors?.[0].rent && !staffHousedRentIsZero) return false
    if (maySaleProperty && isNil(forSale)) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  const mapSituationValues: MapToChoices<SituationType[]> = values =>
    values.map(value => ({
      key: value,
      isSelected: situation === value,
      onClick: () => {
        clonedMortgagors[0].situationType = value
        setContext({ mortgagors: clonedMortgagors })
        const shouldGoToNextPage =
          (value === SituationType.Owner && usage !== Usage.Primary) ||
          (value === SituationType.Owner && simulationType === SimulationType.Buyout) ||
          value === SituationType.Sheltered

        if (shouldGoToNextPage) {
          goToNextRoute()
          return
        }
        setActiveField(1)
      },
      label: t(`sentences.lodging.situation.values.${value}`),
    }))

  const forSaleChoices = [
    {
      key: 'yes',
      isSelected: !!forSale,
      onClick: () => {
        setContext({ ownPropertyForSale: true })
      },
      label: t('sentences.lodging.forSale.values.yes'),
    },
    {
      key: 'no',
      isSelected: isNil(forSale) ? false : !forSale,
      onClick: () => {
        setContext({ ownPropertyForSale: false })
      },
      label: t('sentences.lodging.forSale.values.no'),
    },
  ]

  const saveRent = (value: string) => {
    clonedMortgagors[0].rent = parseInt(value)
    setContext({
      mortgagors: clonedMortgagors,
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'situation' } }}>
      <Title>{t('sentences.lodging.title')}</Title>
      <div>
        {t('sentences.lodging.situation.sentence')}
        <Prompt isFilled={!!situation} onClick={() => setActiveField(0)}>
          {t(`sentences.lodging.situation.values.${situation || 'tenant'}`)}
        </Prompt>
        {activeField > 0 && hasRentToPay && (
          <>
            {t('sentences.lodging.rent.sentences.0')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'lodgingSituationRent' } }}
              inputProps={{ autoFocus: activeField === 1, placeholder: '700', type: 'integer' }}
              onChange={saveRent}
              value={mortgagors?.[0].rent?.toString() || ''}
            />
            {t('sentences.lodging.rent.sentences.1')}
          </>
        )}
        {activeField > 0 && maySaleProperty && (
          <>
            {t('sentences.lodging.forSale.sentences.0')}
            <Prompt isFilled={!isNil(forSale)} onClick={() => setActiveField(1)}>
              {t(`sentences.lodging.forSale.values.${forSale || isNil(forSale) ? 'yes' : 'no'}`)}
            </Prompt>
            {t('sentences.lodging.forSale.sentences.1')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSituationValues(Object.values(SituationType))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingSituationType' } }}
        />
      )}
      {activeField === 1 && maySaleProperty && (
        <Choices
          choices={forSaleChoices}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'lodgingForSale' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {getIsFilled() && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
