import { mapContractTypeValues } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapContractType'
import { mapExecutiveValues } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapExecutiveValues'
import { mapMonths } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapMonths'
import { mapTrialValues } from '@pretto/app/src/Sentences/ComortgagorOccupation/Sector/lib/mappers/mapTrialValues'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import {
  Months,
  OccupationContractType,
  OccupationPrivateStatus,
  OccupationPrivateTrial,
} from '@pretto/app/src/Sentences/types/enums'

interface PrivateChoicesProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PrivateChoices: React.FC<PrivateChoicesProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { mortgagors } = context
  const { goToNextRoute } = useRoutesGraph()
  const isOnTrial = mortgagors?.[1].trialPeriod === true

  const handleChoiceClick = () => {
    if (!isOnTrial) {
      goToNextRoute()
    }
  }

  return (
    <>
      {activeField === 1 && (
        <Choices
          choices={mapContractTypeValues(Object.values(OccupationContractType), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPrivateContractType' },
          }}
          onChoiceClick={() => onSetActive(2)}
        />
      )}
      {activeField === 2 && mortgagors?.[1].contractType === 'longTerm' && (
        <Choices
          choices={mapExecutiveValues(Object.values(OccupationPrivateStatus), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPrivateStatus' },
          }}
          onChoiceClick={() => {
            onSetActive(3)
          }}
        />
      )}
      {activeField === 3 && mortgagors?.[1].contractType === 'longTerm' && (
        <Choices
          choices={mapTrialValues(Object.values(OccupationPrivateTrial), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPrivateTrial' },
          }}
          onChoiceClick={handleChoiceClick}
        />
      )}
      {activeField === 2 && mortgagors?.[1].contractType === 'shortTerm' && (
        <Choices
          choices={mapMonths(Object.values(Months), context)}
          events={{
            eventName: EventName.SimulationChoiceClicked,
            eventPayload: { choice: 'coMortgagorOccupationPrivateShortTerm' },
          }}
          onChoiceClick={() => {
            onSetActive(4)
          }}
        />
      )}
    </>
  )
}
