import SubscribeCompletedPage from '@pretto/bricks/app/subscribe/pages/SubscribeCompletedPage'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'
import { isInternetExplorer } from '@pretto/bricks/core/utility/deprecation'
import DeprecatedBrowserBar from '@pretto/bricks/shared/components/DeprecatedBrowserBar'

import { Provider as LoadingProvider } from '@pretto/app-core/loading/containers/context'
import { NotificationsProvider } from '@pretto/app-core/notifications/notifications'

import { ResultPageController } from '@pretto/app/src/Result/Containers/controller'
import { ProjectPartnerChoice } from '@pretto/app/src/projectChoice/containers/projectPartnerChoice'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import cloneDeep from 'lodash/cloneDeep'
import cloneDeepWith from 'lodash/cloneDeepWith'
import { memo } from 'react'
import { Route as DefaultRoute, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom'

import AccountPage from './Account/Containers/AccountPage'
import ApplicationDocumentsPage from './Application/Containers/ApplicationDocumentsPage'
import ApplicationNavigation from './Application/Containers/ApplicationNavigation/ApplicationNavigation'
import ApplicationPage from './Application/Containers/ApplicationPage'
import ApplicationSubmitPage from './Application/Containers/ApplicationSubmitPage'
import AuthProvider from './Auth/Containers/AuthProvider'
import EmailVerificationSentPage from './Auth/Containers/EmailVerificationSentPage'
import LoginConfirmedPage from './Auth/Containers/LoginConfirmedPage'
import LoginPage from './Auth/Containers/LoginPage'
import LogoutPage from './Auth/Containers/LogoutPage'
import { Route } from './Auth/Containers/Route/Route'
import { getAuthToken } from './Auth/lib/helpers'
import BookingAppointmentPage from './Booking/Containers/BookingAppointmentPage'
import BookingEmbeddedPage from './Booking/Containers/BookingEmbeddedPage'
import BookingSummaryPage from './Booking/Containers/BookingSummaryPage'
import NotFoundPage from './Errors/Containers/NotFoundPage'
import InviteFormPage from './Invite/Containers/InviteFormPage'
import NavigationProvider from './NavigationProvider'
import PreLeadPage from './PreLead/Containers/PreLeadPage'
import PrivacyPage from './Privacy/Containers/PrivacyPage'
import MaturityPage from './Project/Containers/MaturityPage'
import { MaturityPropertyLeadingSourcePage } from './Project/Containers/MaturityPropertyLeadingSourcePage/MaturityPropertyLeadingSourcePage'
import MaturityPropertyPage from './Project/Containers/MaturityPropertyPage'
import MaturityUpdatePage from './Project/Containers/MaturityUpdatePage'
import { maturityValuesWithOptions } from './Project/config/maturity'
import AlertsCreatePage from './PropertySearch/Containers/AlertsCreatePage'
import AlertsDispatcher from './PropertySearch/Containers/AlertsDispatcher'
import AlertsEditPage from './PropertySearch/Containers/AlertsEditPage'
import PropertySearchProvider from './PropertySearch/Containers/PropertySearchProvider'
import ReferAppointment from './Refer/Containers/ReferAppointment'
import ReferSignup from './Refer/Containers/ReferSignup'
import { SentencesModule } from './Sentences'
import DirectionsPage from './SharedContainers/DirectionsPage'
import SignUpPage from './SignUp/Containers/signUpPage'
import OptimizePage from './Simulation/Containers/OptimizePage'
import SponsorshipPage from './Sponsorship/Containers/SponsorshipPage'
import UserProvider, { useUser } from './User/Containers/UserProvider'
import apiClient from './api'
import { PROJECT } from './apollo'
import ConsentManager from './components/ConsentManager'
import ErrorBoundary from './components/ErrorBoundary'
import { controlSubdomain, isEmbedded } from './config/domain'
import { Loader } from './config/loader'
import { DashboardPage } from './dashboard/containers/dashboard'
import { persistExperiments } from './lib/abtest'
import { getUtmFlagsLastClicked, inIframe } from './lib/helpers'
import { initSentry } from './lib/sentry'
import { TrackPageView } from './lib/tracking'
import MandateController from './mandate/containers/controller'
import OnboardingController from './onboarding/containers/controller'
import { OverviewPage } from './overview/containers/overview'
import { isPartner } from './projectChoice/lib/isPartner'
import { PurchaseOfferPage } from './purchaseOffer/containers/purchaseOfferPage'

const history = createBrowserHistory()
initSentry(history)

// Add Sentry instrumentation for legacy routing
const RouteLegacy = Sentry.withSentryRouting(DefaultRoute)

persistExperiments()

const RootController = props => {
  const { isOnboardingDone, typology } = useUser()

  // Check if the subdomain is allowed and redirect otherwise
  controlSubdomain()

  const { utm_campaign } = getUtmFlagsLastClicked()

  if (typology === 'prospect') {
    if (isPartner(utm_campaign)) {
      return (
        <Switch>
          <Route exact path="/" component={ProjectPartnerChoice} />
          <Route path="/project" component={SentencesModule} />
        </Switch>
      )
    }

    return <SentencesModule {...props} />
  }

  if (typology === 'preLead') {
    return (
      <Switch>
        <Route exact path="/" component={PreLeadPage} />
        <Route path="/project" component={SentencesModule} />
      </Switch>
    )
  }

  if (inIframe()) {
    return <SubscribeCompletedPage href={`${window.location.origin}/onboarding`} />
  }

  if (!isOnboardingDone) {
    return <Redirect to="/onboarding" />
  }

  return <DashboardPage {...props} />
}

const App = () => (
  <ThemeProvider>
    <ErrorBoundary>
      <Router history={history}>
        <LoadingProvider loaderComponent={<Loader />}>
          <NotificationsProvider>
            <>
              {!isEmbedded() && <ConsentManager />}

              {isInternetExplorer && <DeprecatedBrowserBar />}

              <ApolloProvider client={apiClient}>
                <AuthProvider>
                  <NavigationProvider>
                    <UserProvider>
                      <PropertySearchProvider>
                        <>
                          <Switch>
                            <Route exact path="/(project.*)?" component={RootController} />
                            <Route exact path="/404" component={NotFoundPage} />
                            <Route path={['/capacity', '/simulation']} component={ResultPageController} />
                            <Route exact path="/email-verification-sent" component={EmailVerificationSentPage} />
                            <Route exact path="/simulation/optimize" component={OptimizePage} />
                            <Route
                              scope={['preLead']}
                              exact
                              path="/refer/friend/appointment"
                              component={ReferAppointment}
                            />
                            <Route exact path="/refer/friend/:id" component={ReferSignup} />
                            <Route exact path="/login" component={LoginPage} />
                            <Route scope={['preLead', 'client']} exact path="/logout" component={LogoutPage} />
                            <Route
                              scope={['prospect', 'preLead']}
                              exact
                              path="/login/no-account"
                              component={DirectionsPage}
                            />
                            <Route scope={['prospect']} exact path="/login-confirmed" component={LoginConfirmedPage} />
                            <Route scope={['prospect']} exact path="/signup" component={SignUpPage} />
                            <Route exact path="/privacy" component={PrivacyPage} />
                            <Route scope={['preLead', 'client']} exact path="/account" component={AccountPage} />
                            <Route exact path="/purchase-offer" component={PurchaseOfferPage} />
                            <Route scope={['client']} exact path="/sponsorship" component={SponsorshipPage} />
                            <Route scope={['client']} exact path="/appointment" component={BookingSummaryPage.Legacy} />
                            <Route scope={['client']} exact path="/booking" component={BookingSummaryPage} />
                            <Route
                              scope={['client']}
                              exact
                              path="/booking/appointment"
                              component={BookingAppointmentPage}
                            />
                            <Route
                              scope={['client']}
                              onboardedOnly
                              exact
                              path="/booking/embedded"
                              component={BookingEmbeddedPage}
                            />
                            <Route
                              scope={['preLead', 'client']}
                              exact
                              path="/property-search/alerts"
                              component={AlertsDispatcher}
                            />
                            <Route exact path="/property-search/alerts/create" component={AlertsCreatePage} />
                            <Route exact path="/property-search/alerts/:id/edit" component={AlertsEditPage} />
                            <Route
                              scope={['client']}
                              onboardedOnly
                              exact
                              path="/application"
                              component={ApplicationPage}
                              shouldScrollTop={false}
                            />
                            <Route
                              scope={['client']}
                              onboardedOnly
                              exact
                              path="/application/submit"
                              component={ApplicationSubmitPage}
                            />
                            <Route
                              scope={['client']}
                              onboardedOnly
                              path="/application"
                              component={ApplicationNavigation}
                            />
                            <Route scope={['client']} path="/onboarding" component={OnboardingController} />
                            <Route scope={['client']} exact path="/maturity" component={MaturityPage} />
                            <Route exact path="/maturity-update" component={MaturityUpdatePage} />
                            <Route
                              scope={['client']}
                              exact
                              path={`/maturity/property/:option(${maturityValuesWithOptions.join('|')})`}
                              component={MaturityPropertyPage}
                            />
                            <Route
                              scope={['client']}
                              exact
                              path={`/maturity/property-leading-source/:option(${maturityValuesWithOptions.join('|')})`}
                              component={MaturityPropertyLeadingSourcePage}
                            />
                            <Route
                              scope={['client']}
                              onboardedOnly
                              exact
                              path="/mandate"
                              component={MandateController.Dispatcher}
                            />
                            <Route
                              scope={['client']}
                              onboardedOnly
                              exact
                              path="/mandate/:step"
                              component={MandateController}
                            />
                            <Route scope={['client']} exact path="/overview" component={OverviewPage} />
                            <Route scope={['client']} exact path="/invite" component={InviteFormPage} />
                            <RouteLegacy exact path="/mandat" component={MandateController.Legacy} />
                            <RouteLegacy exact path="/information/:step" component={ApplicationPage.Legacy} />
                            <RouteLegacy exact path="/docs*" component={ApplicationDocumentsPage.Legacy} />
                            <RouteLegacy exact path="/comortgagor-contact-info" component={ApplicationPage.Legacy} />
                            <Route component={NotFoundPage} />
                          </Switch>
                          <TrackPageView />
                        </>
                      </PropertySearchProvider>
                    </UserProvider>
                  </NavigationProvider>
                </AuthProvider>
              </ApolloProvider>
            </>
          </NotificationsProvider>
        </LoadingProvider>
      </Router>
    </ErrorBoundary>
  </ThemeProvider>
)

export default memo(Sentry.withProfiler(App))

window.detectToken = () => {
  // eslint-disable-next-line no-console
  console.info('[devtools] token requested')
  return getAuthToken()
}

window.impersonate = token => {
  // eslint-disable-next-line no-console
  console.info('[devtools] impersonate with token', token)
  Sentry.setContext('devtools', {
    impersonate: true,
  })
  return (window.location.href = `/impersonate#${token}`)
}

window.populate = async (projectId, token) => {
  // eslint-disable-next-line no-console
  console.info('[devtools]', 'populating with projectId', projectId)
  Sentry.setContext('devtools', {
    populate: true,
  })

  try {
    const { data } = await apiClient.query({
      context: { headers: { authorization: token ? `Bearer ${token}` : `Visitor ${projectId}` } },
      fetchPolicy: 'network-only',
      query: PROJECT,
    })

    const excludeKeys = ['id', '__typename']

    const { project } = cloneDeepWith(cloneDeep(data), value => {
      if (value && typeof value === 'object') {
        excludeKeys.forEach(key => {
          delete value[key]
        })
      }
    })

    // eslint-disable-next-line no-console
    console.info('[devtools]', 'received project', project)

    // reconnect to new sentences context
    // store.dispatch({ type: 'INIT_PROJECT_SENTENCES', payload: project })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[devtools]', error)
  }
}
