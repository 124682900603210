import { LOCAL_STORAGE_IMMO } from '@pretto/app/src/Sentences/config/storage/storage'
import { setItem } from '@pretto/app/src/config/itemStorage'

const PRETTO_SEARCH_SOURCE = 'prettosearch'

export const getContextFromUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const source = urlParams.get('utm_source')

  if (source === PRETTO_SEARCH_SOURCE) {
    setItem(LOCAL_STORAGE_IMMO, 'true')

    return { isNotVisiblePropertySearch: true }
  }
}
