import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { ErrorMessage } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { SciType } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

export const SciTypePage: React.FC = () => {
  const context = useSentences()
  const { setContext, sciType } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && sciType))

  const saveSciType = (value: SciType) => {
    setContext({ sciType: value })
    if (value !== SciType.IS) return goToNextRoute()
  }

  // TODO: externalize this function
  const mapSciTypeValues = (values: SciType[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: sciType === value,
        onClick: () => saveSciType(value),
        label: t(`sentences.introduction.sci.values.${value}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sciType' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.sci.type')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!sciType}>
          {t(`sentences.introduction.sci.values.${sciType || 'ir'}`)}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSciTypeValues(Object.values(SciType))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'sciType' } }}
        />
      )}
      {sciType === SciType.IS && <ErrorMessage>{t(`sentences.introduction.sci.error.sciType`)}</ErrorMessage>}
      {sciType === SciType.IR && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
