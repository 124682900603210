import sentencesTranslations from '@pretto/app/src/Sentences/config/translations/en'

/* eslint-disable max-lines */
const translation = {
  ...sentencesTranslations,
  navigation: {
    login: 'Log In',
    dashboard: 'Dashboard',
    documents: 'Documents',
    folder: 'Folder',
    modifySimulation: 'Modify My Simulation',
    simulator: 'Simulator',
    capacity: 'Budget',
    result: 'Result',
    goBack: 'Go Back',
  },
  languageInfo: {
    title: ' 🇬🇧 Switching Language',
    message:
      '<p>The rest of your customer journey has been designed in French.</p><p>We advise you to enable the <strong>auto-translation feature in your browser settings</strong> (<DocLink>see documentation</DocLink>).</p><p>No worries, your appointment will take place with an <strong>English-speaking expert!</strong>',
    buttonShort: 'Proceed',
    buttonLong: 'Proceed to the French website',
    close: 'Close',
  },
  prettoSearchHeader: {
    message: 'Simplify your property hunt with <Strong>Pretto Search</Strong>. We find real estate listings for you!',
  },
  pushy: {
    title: 'Save your progress !',
    agreement: 'I agree that Pretto and its trusted partners can contact me to study my real estate project.',
    disclaimer: 'No worries ! Your <strong>personal data is protected</strong> and used only for your loan search.',
    continue: 'Continue',
    cgu: {
      acceptTerms:
        'By continuing, you are creating a Pretto account and accepting our <CguLink>terms of use</CguLink> and our <PrivacyLink>privacy policy</PrivacyLink>.',
    },
    placeholder: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phoneNumber: 'Phone number',
    },
  },
  mutualizedAgenda: {
    title: 'Secure your mortgage with our expert guidance.',
    description:
      'Choose a slot in the schedule of <strong>our {{advisorLabel}}s</strong> at Pretto for a phone appointment.',
    appointmentDuration: 'Appointment duration : 30 minutes',
    videoCallAvailable: 'Video call available',
    localTime: 'Local time : {{timezone}}',
    skipAppointment: 'Continue without booking an appointment',
    backToResults: 'Go back to results',
    subscribe: {
      description:
        'The appointment with your {{advisorLabel}} <strong>{{name}}</strong> is <strong>free</strong> and without obligation !',
      confirm: 'Book Now',
      appointmentInfo: 'Your appointment: <strong>{{appointmentDate}}</strong>',
      edit: 'Edit',
      videoCallBook: 'Schedule the appointment with ',
      videoCallName: '{{name}} <strong>as a video call</strong>',
    },
  },
  reduceAmbitionPage: {
    title: 'Let’s make this work!',
    description:
      'At Pretto, our brokers do their very best to support your mortgage application. But as it stands, your downpayment is too low for the property price you are targeting. We suggest you adjust your project if you can. You can also simulate your <CapacityLink>maximum purchasing capacity</CapacityLink>.',
    tweakProject: 'Edit your project:',
    resultsButton: 'Results',
    bumpContribution: {
      label: 'Increase your downpayment',
      labelShort: 'Downpayment',
      tooltip:
        'Required by banks to finance notary and guarantee fees, the downpayment must in general cover <strong>at least 10% of the property price</strong> (more than 20% for non residents).',
    },
    downsizeProject: {
      label: 'Reduce the property price',
      labelShort: 'Price',
      tooltip: 'If you are not yet committed to the seller, we suggest you <strong>make a lower offer</strong>.',
    },
    optionsPage: {
      edit: 'Edit',
      editProfile: 'Edit your profile',
      editFolder: 'Edit your folder',
      message: 'It is possible that the information in your profile is incorrect. You can edit it if necessary.',
    },
  },
  simulation: {
    loader: 'We are testing more than 1,500 mortgage options for your project…',
    editor: {
      title: 'Your Simulation',
      propertyPrice: 'Property Price',
      landPrice: 'Land Price',
      buildPrice: 'Construction Price',
      worksPrice: 'Renovation Cost',
      contribution: 'Downpayment',
      remainingSavings: 'Remaining Savings',
      edit: 'Edit',
    },
    appointmentConfirmed: {
      title: 'Your appointment is confirmed!',
      text: '{{name}}, your English-speaking expert , will call you on <Date>{{start}}</Date>. Until then, prepare for your appointment in your client area.',
      cta: 'Go to my Customer Area',
    },
  },
  noFinancingPage: {
    sorry: 'We are very sorry...',
    noRealEstateOffer: 'We couldn’t find any mortgage offer for your project.',
    noFinancingOffer: 'We couldn’t find any financing offer for your current situation.',
    reasonsMessageShort_one: 'Here are the reason that may explain why:',
    reasonsMessageShort_other: 'Here are the reasons that may explain why:',
    reasonsMessageLong_one: 'Here are the reason that may explain why Pretto can’t assist you on this project:',
    reasonsMessageLong_other: 'Here are the reasons that may explain why Pretto can’t assist you on this project:',
    restoreMessage:
      'The project you have previously set with different parameters can be financed. Your can restore the project to its previous parameters.',
    restoreCta: 'Restore',
    blocking: 'no go',
    warning: 'warning',
    moreInfo: 'Learn more',
    comments: {
      noIncome: 'No income taken into account.',
      chargesAccount:
        'Charges taken into account:\nfuture monthly payment: {{financing.payment}}€, rent: {{project.facts.detailed_charges.rent}}€, ongoing credits: {{project.facts.detailed_charges.credits}}€, alimony: {{project.facts.detailed_charges.child_support}}€, other charges: {{project.facts.detailed_charges.other_charges}}€',
      incomeAccount:
        'Income taken into account:\n{{mortgagorIncomes}}, rental income received: {{financing.monthlyDetailedWeightedIncomes.rental_income}}€, anticipated rents: {{financing.monthlyDetailedWeightedIncomes.expected_rental_income}}€, others: {{financing.monthlyDetailedWeightedIncomes.other}}€',
      indebtednessAfterBridge:
        'Debt ratio = (Current charges + New monthly payment after bridge) / Income taken into account\n\nCurrent charges = {{totalCharges}}€\nNew monthly payment after bridge = {{financing.paymentAfterBridge}}€\nIncome taken into account = {{totalIncomes}}€',
      indebtednessDifferential:
        'Differential debt ratio = (Current charges + New monthly payment - Rental income) / (Income taken into account - rental income)\n\nCurrent charges = {{totalCharges}}€\nNew monthly payment = {{financing.payment}}€\nRental income = {{rentIncomes}}€\nIncome taken into account = {{totalIncomes}}€',
      indebtednessStandard:
        'Debt ratio = (Current charges + New monthly payment) / Income taken into account\n\nCurrent charges = {{totalCharges}}€\nNew monthly payment = {{financing.payment}}€\nIncome taken into account = {{totalIncomes}}€',
      mortgagorIncomeSingle: 'Borrower remuneration: {{financing.monthlyDetailedWeightedIncomes.wages[0]}}€',
      mortgagorIncomeJoint:
        'Borrower remuneration: {{financing.monthlyDetailedWeightedIncomes.wages[0]}}€, co-borrower remuneration: {{financing.monthlyDetailedWeightedIncomes.wages[1]}}€',
      contracts: {
        contractJoinWord: 'and',
        contractPrefixWordShort: 'a ',
        contractPrefixWordLong: 'a',
        seniority: {
          lessThanOneYear: 'less than 1 year',
          year: 'year',
          years: 'years',
        },
        portage_salarial: 'wage portage',
        fixed_term: 'fixed term contract ("CDD")',
        jobless: 'jobless',
        parental_leave: 'on parental leave',
        permanent_executive: 'permanent executive',
        permanent_executive_trial: 'permanent executive in trial period',
        permanent_non_executive: 'permanent non-executive',
        permanent_non_executive_trial: 'permanent non-executive in trial period',
        business_owner_one: 'business owner',
        business_owner_other: 'business owners',
        casual_show_business_worker_one: 'temporary show business worker',
        casual_show_business_worker_other: 'temporary show business workers',
        contractuel_cdd_one: 'contractual in fixed term contract ("CDD")',
        contractuel_cdd_other: 'contractuals in fixed term contract ("CDD")',
        contractuel_cdi_one: 'contractual in permanent contract ("CDI")',
        contractuel_cdi_other: 'contractuals in permanent contract ("CDI")',
        craftsman_one: 'tradesperson',
        craftsman_other: 'tradespersons',
        doctor_one: 'doctor',
        doctor_other: 'doctors',
        farmer_one: 'farmer',
        farmer_other: 'farmers',
        interim_one: 'temporary worker',
        interim_other: 'temporary workers',
        internship_one: 'intern',
        internship_other: 'interns',
        jobseeker_one: 'jobseeker',
        jobseeker_other: 'jobseekers',
        lawyer_one: 'lawyer',
        lawyer_other: 'lawyers',
        liberal_one: 'liberal profession excluding doctor / lawyer',
        liberal_other: 'liberal professions excluding doctors / lawyers',
        retired_one: 'retired',
        retired_other: 'retirees',
        salaried_director_one: 'salaried manager',
        salaried_director_other: 'salaried managers',
        self_employed_one: 'independent worker or entrepreneur',
        self_employed_other: 'independent workers or entrepreneurs',
        student_one: 'student',
        student_other: 'students',
        tenure_one: 'tenured',
        tenure_other: 'tenureds',
        trader_one: 'shop owner',
        trader_other: 'shop owners',
        military_one: 'member of the military',
        military_other: 'members of the military',
        childminder_one: 'childcare assistant',
        childminder_other: 'childcare assistants',
      },
    },
  },
  arrangeCreditsPage: {
    repayWithContribution_one: 'To carry out this project, repay the following loan with the help of your downpayment:',
    repayWithContribution_other:
      'To carry out this project, repay the following loans with the help of your downpayment:',
    repayedLoans_one: 'Repaid Loan',
    repayedLoans_other: 'Repaid Loans',
    amountPerMonthSuffix: '€ / month',
    amountSuffix: '€',
    newContribution: 'New Downpayment',
    repayLoansTitle: 'Repay your loans',
    consolidateDebt_one: 'To carry out this project, ask the bank for a debt consolidation for the following loan:',
    consolidateDebt_other: 'To carry out this project, ask the bank for a debt consolidation for the following loans:',
    consolidatedLoans_one: 'Consolidated loan',
    consolidatedLoans_other: 'Consolidated loans',
    consolidateDebtTitle: 'Consolidate your debt',
    adjustProject: 'Edit your simulation',
    adjustProjectMessage: 'We suggest an adjustment to your project to reduce your debt ratio.',
  },
  nonFinancingSignupPage: {
    title: 'Your project has our full attention',
    message:
      'Your situation deserves a dedicated broker. Make an appointment with one of our specialized <Strong>credit experts</Strong> and find the best financing offer for your profile.',
    free: 'Free',
    noCommitment: 'No commitment',
    bookAppointment: 'Let’s make an appointment!',
  },
  form: {
    mandatoryField: 'This field is required',
    invalidEmail: 'Your email address is not valid',
    invalidPhone: 'This phone number is not valid',
    rectifyEmail: 'Did you mean {{suggestion}}? <CorrectButton>Rectify</CorrectButton>',
  },
  signupForm: {
    defaultTitle: 'Shall we introduce ourselves?',
    contactInfo: 'Your details:',
    placeholders: {
      email: 'Your email address',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Your phone number',
    },
    maturity: {
      placeholder: 'Project progress',
      underStudy: 'I have just started studying the project',
      searchStarted: 'I am actively searching for a property',
      offerMade: 'I’ve made an offer to purchase a property',
      offerAccepted: 'I have an accepted purchase offer',
      saleAgreementSigned: 'I have signed a sale agreement',
    },
    acceptTerms:
      'I agree that Pretto and its trusted partners may contact me to review my real estate project, as well as Pretto’s <CguLink>terms of use</CguLink> and <PrivacyLink>privacy policy</PrivacyLink>.',
    accountExistsError:
      'An account already exists with this email address. Please check that you have entered it correctly.',
    error: 'An error occurred, please try again or contact customer support.',
    mandatoryFieldBooking: 'This box is required to schedule an appointment',
    bookAppointmentCTA: 'Book an appointment',
  },
  faqCard: {
    question: 'Have a question?',
    title: 'Check out the FAQ',
    description: 'All the advice, questions, and answers from the Pretto team.',
    goToFaq: 'Consult (French)',
  },
  guideCard: {
    discover: 'Discover...',
    title: 'Our buyer’s guide',
    description: 'Be ready for your first real estate purchase. All our advice is gathered in a free ebook!',
    download: 'Download (French)',
    imgAlt: 'Cover of the buyer’s guide',
  },
}

export default translation
