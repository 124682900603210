import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import { SimulationType } from '@pretto/app/src/Sentences/types/enums'

export const mapContribution: ComposableMapper = ({ context, payload }) => {
  const updatedPayload = structuredClone(payload)

  updatedPayload.contribution = context.simulationType !== SimulationType.Renegotiation ? context.contribution : null

  return {
    context,
    payload: updatedPayload,
  }
}
