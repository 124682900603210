import { HasCompetitionPage } from '@pretto/app/src/Sentences/Competition/HasCompetition/Containers/HasCompetitionPage'
import { OfferPage } from '@pretto/app/src/Sentences/Competition/Offer/Containers/OfferPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const CompetitionController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/purchase/competition/offer')} component={OfferPage} />
      <Route path={graph.matchRoute('/project/purchase/competition/hasCompetition')} component={HasCompetitionPage} />
      <Redirect to="/" />
    </Switch>
  )
}
