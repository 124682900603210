import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { SimulationType } from '@pretto/app/src/Sentences/types/enums'
import { ProjectKind } from '@pretto/app/src/types/gateway/enums'
import type { Project, Purchase } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

const mapSimulationTypeToProjectKind = (simulationType: SimulationType | undefined | null): ProjectKind => {
  switch (simulationType) {
    case SimulationType.Renegotiation:
      return ProjectKind.Renegotiation
    // Capacity also returns purchase, by design
    case SimulationType.Capacity:
    case SimulationType.Purchase:
    default:
      return ProjectKind.Purchase
  }
}

export const mapProjectKind: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)
  const purchase: Purchase = structuredClone(updatedPayload.purchase) ?? {}

  updatedPayload.project_kind = mapSimulationTypeToProjectKind(context.simulationType)
  if (!isNil(context.worksPrice)) purchase.works_price = context.worksPrice
  if (context.simulationType !== SimulationType.Renegotiation) updatedPayload.purchase = mapBuyout(purchase, context)

  return {
    context,
    payload: updatedPayload,
  }
}

export const mapBuyout = (purchase: Purchase, context: ProjectContext): Purchase => {
  purchase.specific_amounts = []

  if (context.simulationType === SimulationType.Buyout && !isNil(context.buyoutAmount)) {
    const specificAmounts = [
      {
        label: 'Montant de la soulte',
        amount: context.buyoutAmount,
      },
    ]

    if (context.hasBuyoutCredit && !isNil(context.buyoutCreditRemainingPrincipal)) {
      specificAmounts.push({
        label: 'Montant du CRD soulte',
        amount: context.buyoutCreditRemainingPrincipal,
      })
    }

    purchase.specific_amounts = specificAmounts
  }

  return purchase
}
