import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { isNotSolo } from '@pretto/app/src/Sentences/lib/validators/context'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const HasCompetitionPage: React.FC = () => {
  const context = useSentences()
  const { hasCompetition, setContext } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(hasCompetition)))

  const mapHasCompetitionValues: MapToChoices<YesNo[]> = values =>
    values.map(value => {
      return {
        key: value,
        value,
        isSelected:
          (value === YesNo.Yes && hasCompetition) || (value === YesNo.No && !hasCompetition && !isNil(hasCompetition)),
        onClick: () => {
          setContext({ hasCompetition: value === YesNo.Yes })
          if (value === YesNo.No) goToNextRoute()
        },
        label: t(`sentences.competition.hasCompetition.values.${value}`),
      }
    })

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'hasCompetition' } }}>
      <Title>{t('sentences.competition.title')}</Title>
      <div>
        {t('sentences.competition.hasCompetition.sentences.0')}
        {isNotSolo(context) && t('sentences.competition.hasCompetition.sentences.1')}
        <Prompt
          isFilled={!isNil(hasCompetition)}
          onClick={() => {
            setActiveField(0)
          }}
        >
          {t(
            `sentences.competition.hasCompetition.values.${
              hasCompetition || isNil(hasCompetition) ? YesNo.Yes : YesNo.No
            }`
          )}
        </Prompt>
        {t('sentences.competition.hasCompetition.sentences.2')}
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasCompetitionValues(Object.values(YesNo), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'hasCompetition' } }}
        />
      )}
      {!isNil(hasCompetition) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
