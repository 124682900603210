import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { t } from '@pretto/app/src/lib/i18n'

export const ConstructionPage: React.FC = () => {
  const context = useSentences()
  const { constructionPrice, setContext } = context

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'construction' } }}>
      <Title>{t('sentences.property.title')}</Title>
      <div>
        {t('sentences.property.construction.sentences.0')}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'propertyConstructionPrice' } }}
          inputProps={{ autoFocus: true, placeholder: '200 000', type: 'integer' }}
          onChange={(value: string) => {
            setContext({ constructionPrice: parseInt(value) })
          }}
          value={constructionPrice?.toString() || ''}
        />
        {t('sentences.property.construction.sentences.1')}.
      </div>
      {Boolean(constructionPrice) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
