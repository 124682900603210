import { g } from '@pretto/bricks/components/layout'

import { RevenueBox } from '@pretto/app/src/Sentences/Income/Revenue/components/RevenueBox'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import type { Revenue } from '@pretto/app/src/Sentences/types/compensation'
import { RevenueType } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import findLast from 'lodash/findLast'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface RevenueBoxesProps {
  activeField: number
  onPromptClick: () => void
  onChoiceClick: () => void
}

const Box = styled(RevenueBox)`
  & + & {
    margin-top: ${g(2)};
  }
`

export const RevenueBoxes: React.FC<RevenueBoxesProps> = ({ activeField, onPromptClick, onChoiceClick }) => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const [editableId, setEditableId] = useState<string | null>(null)

  useEffect(() => {
    const lastEmpty = findLast(
      mortgagors?.[0].revenues || [],
      revenue => revenue.amount === null && revenue.type === null
    )

    if (lastEmpty) setEditableId(lastEmpty.id)
  }, [mortgagors])

  const currentMortgagor = mortgagors?.[0]

  return (
    <>
      {currentMortgagor?.revenues
        ?.filter(revenue => !revenue._delete)
        .map((revenue, index) => {
          const setRevenue = (updatedRevenue: Revenue) => {
            setContext(({ mortgagors }) => ({
              mortgagors:
                mortgagors?.map(mortgagor => {
                  if (currentMortgagor.id === mortgagor.id) {
                    return {
                      ...mortgagor,
                      revenues:
                        mortgagor.revenues?.map(currentRevenue => {
                          if (currentRevenue.id === revenue.id) {
                            return updatedRevenue
                          }

                          return currentRevenue
                        }) ?? [],
                    }
                  }

                  return mortgagor
                }) ?? [],
            }))
          }

          const choices = Object.values(RevenueType).map(value => {
            return {
              key: value,
              isSelected: revenue.type === value,
              onClick: () => {
                setRevenue({ ...revenue, type: value })
              },
              label: t(`sentences.income.revenue.values.${value}`),
            }
          })

          const handleChangeAmount = (value: string) => {
            setRevenue({ ...revenue, amount: parseInt(value) })
          }

          return (
            <Box
              key={revenue.id}
              activeField={activeField}
              index={index}
              isEditable={editableId === revenue.id}
              onClick={() => {
                setEditableId(revenue.id)
              }}
              onRemove={() => {
                setRevenue({ id: revenue.id, _delete: true })
              }}
              onPromptClick={onPromptClick}
              onChoiceClick={onChoiceClick}
              onChangeAmount={handleChangeAmount}
              revenue={revenue}
              choices={choices}
            />
          )
        })}
    </>
  )
}
