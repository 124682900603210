import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'

export const mapCompetition: ComposableMapper = ({ context, payload }) => {
  if (!payload.solicited_banks || !payload.solicited_banks.length) return { payload, context }

  const updatedContext: ProjectContext = structuredClone(context)
  updatedContext.competitionId = payload.solicited_banks[0].id ?? null
  updatedContext.competitionBank = payload.solicited_banks[0].bank_slug ?? null
  updatedContext.competitionDuration = payload.solicited_banks[0].duration ?? null
  updatedContext.competitionRate = payload.solicited_banks[0].rate ?? null

  return {
    payload,
    context: updatedContext,
  }
}
