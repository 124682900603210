import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { ProgressBar } from '@pretto/zen/atoms/progressBars/ProgressBar/ProgressBar'

import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useTimeline } from '@pretto/app/src/Sentences/contexts/TimelineContext'
import { ArrowLeft, QuestionMarkCircle } from '@pretto/picto'

import styled from 'styled-components'

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: ${g(3)};
  padding: ${g(1.5)} 0;
`

const PreviousButton = styled(Link)`
  cursor: pointer;
  flex-shrink: 0;
  height: ${g(4)};
  width: ${g(4)};

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
`

const Progress = styled(ProgressBar)`
  flex-grow: 1;
  cursor: pointer;
  min-height: ${g(4)};
`

const InfoButton = styled.button`
  appearance: none;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  height: ${g(4)};
  margin: 0 ${g(-1 / 2)};
  padding: 0;
  width: ${g(4)};

  svg {
    display: block;
    margin: auto;
  }
`

export const Navbar: React.FC = props => {
  const { previousRoute } = useRoutesGraph()

  const { progress, toggle } = useTimeline()

  return (
    <Container {...props}>
      {previousRoute?.path && (
        <PreviousButton href={previousRoute.path}>
          <ArrowLeft />
        </PreviousButton>
      )}

      <Progress onClick={toggle} value={progress} />

      <InfoButton onClick={toggle}>
        <QuestionMarkCircle />
      </InfoButton>
    </Container>
  )
}
