import type { Income as ContextIncome, ExtraIncome, Revenue } from '@pretto/app/src/Sentences/types/compensation'
import { Deletable } from '@pretto/app/src/Sentences/types/deletable'
import { IncomePeriod, IncomeType, RevenueType } from '@pretto/app/src/Sentences/types/enums'
import type { Mortgagor as ContextMortgagor } from '@pretto/app/src/Sentences/types/mortgagor'
import { IncomeKind, Recurrence, Taxes } from '@pretto/app/src/types/gateway/enums'
import type { Income as PayloadIncome } from '@pretto/app/src/types/gateway/schema'

const mapPeriodToRecurrence = (period: IncomePeriod): Recurrence => {
  switch (period) {
    case IncomePeriod.Year:
      return Recurrence.Yearly
    case IncomePeriod.Month:
    default:
      return Recurrence.Monthly
  }
}

const mapIncomeTypeToTaxes = (type: IncomeType): Taxes => {
  switch (type) {
    case IncomeType.Gross:
      return Taxes.Before
    case IncomeType.Net:
    default:
      return Taxes.After
  }
}

export const mapIncome = (income: ContextIncome): PayloadIncome => ({
  amount: income.amount,
  period: income.period && mapPeriodToRecurrence(income.period),
  taxes: income.type && mapIncomeTypeToTaxes(income.type),
})

export const mapBonus = (mortgagor: ContextMortgagor): PayloadIncome | null | undefined => {
  if (!mortgagor.bonus) {
    return undefined
  }

  if (!mortgagor.hasBonus) {
    return null
  }

  return mapIncome(mortgagor.bonus)
}

const mapRevenueType = (revenueType: RevenueType): IncomeKind | undefined => {
  switch (revenueType) {
    case RevenueType.ChildSupport:
      return IncomeKind.ChildSupport
    case RevenueType.ExpatriationBonus:
      return IncomeKind.ExpatriationBonus
    case RevenueType.FamilyAllowances:
      return IncomeKind.FamilyAllowances
    case RevenueType.ProfessionalBenefits:
      return IncomeKind.ProfessionalBenefits
    case RevenueType.Rent:
      return IncomeKind.Rent
    default:
      return undefined
  }
}

const mapRevenue = (revenue: Revenue): ExtraIncome | Deletable<ExtraIncome> => {
  const extraIncome: ExtraIncome = {}

  if (revenue._delete) {
    extraIncome.id = revenue.id
    extraIncome._delete = true

    return extraIncome
  }

  if (revenue.id) extraIncome.id = revenue.id
  if (revenue.type) extraIncome.kind = mapRevenueType(revenue.type)
  if (revenue.amount) extraIncome.amount = revenue.amount

  return extraIncome
}

export const mapRevenues = (revenues: Revenue[]): Array<ExtraIncome | Deletable<ExtraIncome>> | undefined => {
  if (!revenues.length) return

  return revenues.map(mapRevenue)
}
