import { BankPage } from '@pretto/app/src/Sentences/Loan/Bank/Containers/BankPage'
import { DateDurationPage } from '@pretto/app/src/Sentences/Loan/DateDuration/Containers/DateDurationPage'
import { PaymentPage } from '@pretto/app/src/Sentences/Loan/Payment/Containers/PaymentPage'
import { RatePage } from '@pretto/app/src/Sentences/Loan/Rate/Containers/RatePage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const LoanController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/renegotiation/loan/bank')} component={BankPage} />
      <Route path={graph.matchRoute('/project/renegotiation/loan/payment')} component={PaymentPage} />
      <Route path={graph.matchRoute('/project/renegotiation/loan/rate')} component={RatePage} />
      <Route path={graph.matchRoute('/project/renegotiation/loan/dateDuration')} component={DateDurationPage} />
      <Redirect to="/" />
    </Switch>
  )
}
