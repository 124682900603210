import {
  OccupationContractType,
  OccupationOthersStatus,
  OccupationPublicStatus,
  OccupationType,
} from '@pretto/app/src/Sentences/types/enums'
import { Contract } from '@pretto/app/src/types/gateway/enums'
import type { Maybe } from '@pretto/app/src/types/gateway/schema'

export const mapContractType = (contract?: Maybe<Contract>): OccupationContractType | null => {
  switch (contract) {
    case Contract.ContractuelCdd:
    case Contract.Interim:
    case Contract.FixedTerm:
    case Contract.Internship:
    case Contract.CasualShowBusinessWorker:
    case Contract.Jobseeker:
    case Contract.ParentalLeave:
      return OccupationContractType.ShortTerm
    case Contract.ContractuelCdi:
    case Contract.BusinessOwner:
    case Contract.Craftsman:
    case Contract.Doctor:
    case Contract.Farmer:
    case Contract.Lawyer:
    case Contract.Jobless:
    case Contract.Liberal:
    case Contract.PermanentExecutive:
    case Contract.PermanentExecutiveTrial:
    case Contract.PermanentNonExecutive:
    case Contract.PermanentNonExecutiveTrial:
    case Contract.PermanentUnspecified:
    case Contract.PortageSalarial:
    case Contract.Retired:
    case Contract.SalariedDirector:
    case Contract.Student:
    case Contract.Tenure:
    case Contract.Trader:
      return OccupationContractType.LongTerm
    case Contract.SelfEmployed:
    default:
      return null
  }
}

export const mapOccupationType = (contract?: Maybe<Contract>): OccupationType | null => {
  switch (contract) {
    case Contract.Doctor:
      return OccupationType.Medical
    case Contract.PermanentExecutive:
    case Contract.PermanentExecutiveTrial:
    case Contract.PermanentNonExecutive:
    case Contract.PermanentNonExecutiveTrial:
    case Contract.FixedTerm:
      return OccupationType.Private
    case Contract.Tenure:
    case Contract.Internship:
    case Contract.ContractuelCdd:
    case Contract.ContractuelCdi:
      return OccupationType.Public
    case Contract.Interim:
    case Contract.CasualShowBusinessWorker:
    case Contract.BusinessOwner:
    case Contract.Craftsman:
    case Contract.Farmer:
    case Contract.Lawyer:
    case Contract.Liberal:
    case Contract.ParentalLeave:
    case Contract.Retired:
    case Contract.Jobseeker:
    case Contract.Student:
    case Contract.Jobless:
    case Contract.SelfEmployed:
    case Contract.PortageSalarial:
    case Contract.SalariedDirector:
    case Contract.Trader:
      return OccupationType.Others
    // Couldn't find a match in occupation other status or public status
    case Contract.Childminder:
    case Contract.Military:
    case Contract.PermanentUnspecified:
    default:
      return null
  }
}

export const mapExecutiveRole = (contract?: Maybe<Contract>): boolean | undefined => {
  switch (contract) {
    case Contract.PermanentNonExecutive:
    case Contract.PermanentNonExecutiveTrial:
      return false
    case Contract.PermanentExecutive:
    case Contract.PermanentExecutiveTrial:
      return true
    case Contract.ContractuelCdd:
    case Contract.Interim:
    case Contract.FixedTerm:
    case Contract.Internship:
    case Contract.CasualShowBusinessWorker:
    case Contract.Jobseeker:
    case Contract.ParentalLeave:
    case Contract.ContractuelCdi:
    case Contract.BusinessOwner:
    case Contract.Craftsman:
    case Contract.Doctor:
    case Contract.Farmer:
    case Contract.Lawyer:
    case Contract.Jobless:
    case Contract.Liberal:
    case Contract.PermanentUnspecified:
    case Contract.PortageSalarial:
    case Contract.Retired:
    case Contract.SalariedDirector:
    case Contract.SelfEmployed:
    case Contract.Student:
    case Contract.Tenure:
    case Contract.Trader:
    default:
      return undefined
  }
}

export const mapOccupationStatus = (
  contract?: Maybe<Contract>
): OccupationPublicStatus | OccupationOthersStatus | undefined => {
  switch (contract) {
    case Contract.Tenure:
      return OccupationPublicStatus.Tenure
    case Contract.SelfEmployed:
      return OccupationOthersStatus.SelfEmployed
    case Contract.ContractuelCdd:
      return OccupationPublicStatus.Contractor
    case Contract.ContractuelCdi:
      return OccupationPublicStatus.Contractor
    case Contract.BusinessOwner:
      return OccupationOthersStatus.BusinessOwner
    case Contract.CasualShowBusinessWorker:
      return OccupationOthersStatus.CasualShowBusinessWorker
    case Contract.Craftsman:
      return OccupationOthersStatus.Craftsman
    case Contract.Farmer:
      return OccupationOthersStatus.Farmer
    case Contract.Lawyer:
      return OccupationOthersStatus.Lawyer
    case Contract.Jobless:
      return OccupationOthersStatus.Jobless
    case Contract.Jobseeker:
      return OccupationOthersStatus.Jobseeker
    case Contract.Interim:
      return OccupationOthersStatus.Interim
    case Contract.Trader:
      return OccupationOthersStatus.Trader
    case Contract.Student:
      return OccupationOthersStatus.Student
    case Contract.SalariedDirector:
      return OccupationOthersStatus.SalariedDirector
    case Contract.Retired:
      return OccupationOthersStatus.Retired
    case Contract.ParentalLeave:
      return OccupationOthersStatus.ParentalLeave
    case Contract.PortageSalarial:
      return OccupationOthersStatus.PortageSalarial
    case Contract.Liberal:
      return OccupationOthersStatus.Liberal
    case Contract.Internship:
      return OccupationPublicStatus.Intern
    case Contract.PermanentUnspecified:
      return contract as unknown as OccupationPublicStatus | OccupationOthersStatus
    default:
      return undefined
  }
}

export const mapStartDate = (date?: Maybe<string>): Date | undefined => {
  return date ? new Date(date) : undefined
}

export const mapTrialPeriod = (contract?: Maybe<Contract>): boolean | undefined => {
  switch (contract) {
    case Contract.PermanentExecutiveTrial:
    case Contract.PermanentNonExecutiveTrial:
      return true
    case Contract.PermanentExecutive:
    case Contract.PermanentNonExecutive:
      return false
    default:
      return undefined
  }
}
