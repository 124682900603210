import { temporal } from '@pretto/bricks/core/utility/temporal'

import { getDateStatus } from '@pretto/app/src/Sentences/Maturity/Default/lib/getDateStatus'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { fromFormattedToShortISO, inputDateFormat, placeholeDateFormat } from '@pretto/app/src/Sentences/lib/date'
import { getSearchParamsFromSentencesContext } from '@pretto/app/src/Sentences/lib/prefill'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import {
  isLoggedIn,
  isMaturityComplete,
  isNotPartner,
  isPartner,
} from '@pretto/app/src/Sentences/lib/validators/context'
import { MaturityType, SimulationType } from '@pretto/app/src/Sentences/types/enums'
import { inIframe } from '@pretto/app/src/lib/helpers'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

export const DefaultPage: React.FC = () => {
  const context = useSentences()
  const { agreementSignedDate, maturityType, setContext, simulationType } = context
  const { goToNextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(
    maturityType === MaturityType.SaleAgreementSigned ? 1 : 0,
    Boolean(
      maturityType &&
        ((maturityType === MaturityType.SaleAgreementSigned && agreementSignedDate) ||
          maturityType !== MaturityType.SaleAgreementSigned)
    )
  )
  const [date, setDate] = useState<string>(
    agreementSignedDate ? temporal(agreementSignedDate).format(inputDateFormat) : ''
  )

  const [debouncedDate] = useDebounce(agreementSignedDate, 1200)
  const dateStatus = getDateStatus(debouncedDate, date)

  const getContextUpdates = (maturityType: MaturityType) => {
    if (maturityType !== MaturityType.SearchStarted) {
      return {
        maturityType,
        simulationType: maturityType === MaturityType.UnderStudy ? SimulationType.Capacity : SimulationType.Purchase,
      }
    }

    return { maturityType }
  }

  const handleClickMaturity = (type: MaturityType) => {
    const contextUpdates = getContextUpdates(type)
    if (inIframe() && isPartner(context)) {
      window.open(
        `${window.location.origin}/project/maturity?${getSearchParamsFromSentencesContext(contextUpdates)}`,
        '_blank'
      )
      return
    }
    setContext(contextUpdates)
    if (type !== MaturityType.SaleAgreementSigned) {
      goToNextRoute()
      return
    }
    setActiveField(1)
  }

  const mapMaturityValues = (values: MaturityType[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected: maturityType === value,
        onClick: () => {
          handleClickMaturity(value)
        },
        label: t(`sentences.maturity.default.values.${value}`),
      }
    })
  }

  const handleDateChange = (value: string) => {
    setDate(value)

    setContext({
      agreementSignedDate: new Date(fromFormattedToShortISO(value)),
    })
  }

  const handlePromptClick = () => {
    setActiveField(0)
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'maturityDefault' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.maturity.type')}
        {maturityType === MaturityType.SaleAgreementSigned && isNotPartner(context) && !isLoggedIn(context) ? (
          <> {t('sentences.maturity.default.values.saleAgreementSigned')} </>
        ) : (
          <Prompt onClick={handlePromptClick} isFilled={!isNil(maturityType)}>
            {t(`sentences.maturity.default.values.${isNil(maturityType) ? MaturityType.OfferAccepted : maturityType}`)}
          </Prompt>
        )}
        {activeField >= 1 && maturityType === MaturityType.SaleAgreementSigned && (
          <>
            {t('sentences.maturity.agreementSignedDate')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'introductionMaturityDate' },
              }}
              inputProps={{ autoFocus: activeField === 1, placeholder: placeholeDateFormat, type: 'date' }}
              message={dateStatus.message}
              onChange={handleDateChange}
              state={dateStatus.state}
              value={date}
            />
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapMaturityValues(Object.values(MaturityType))}
          events={[
            { eventName: EventName.MaturityDeclared },
            { eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'maturity' } },
          ]}
        />
      )}
      {simulationType && <Message>{t(`sentences.tips.project.${simulationType}`)}</Message>}
      {isMaturityComplete(context) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
