import { temporal } from '@pretto/bricks/core/utility/temporal'

import { CreditBoxSummary } from '@pretto/app/src/Sentences/Charge/Credits/components/CreditBoxSummary'
import { ChoiceProps, Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { handleCreditDateState } from '@pretto/app/src/Sentences/lib/credits'
import { fromFormattedToShortISO, inputDateFormat, placeholeDateFormat } from '@pretto/app/src/Sentences/lib/date'
import { Credit } from '@pretto/app/src/Sentences/types/credit'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { Container, Cross, Header, Title } from './Styled'

interface CreditBoxProps {
  activeField: number
  index: number
  isEditable: boolean
  onChangeAmount: (value: string) => void
  onChangeDate: (value: Date) => void
  onPromptClick: () => void
  onChoiceClick: () => void
  onClick: () => void
  onRemove: () => void
  credit: Credit
  choices: ChoiceProps[]
}

export const CreditBox: React.FC<CreditBoxProps> = ({
  activeField,
  onPromptClick,
  onChoiceClick,
  index,
  isEditable,
  onChangeAmount,
  onChangeDate,
  onClick,
  onRemove,
  credit,
  choices,
  ...props
}) => {
  const [date, setDate] = useState<string>(temporal(credit.endDate).format(inputDateFormat) || '')
  const [debouncedDate] = useDebounce(credit.endDate, 1200)

  const handleDateChange = (value: string) => {
    setDate(value)
    onChangeDate(new Date(fromFormattedToShortISO(value)))
  }

  const { state, message } = handleCreditDateState(debouncedDate)

  return (
    <Container onClick={onClick} $isEditable={isEditable} {...props}>
      <Header>
        <Title>
          {t('sentences.charge.credits.subtitle.purchase')} {index + 1} :
        </Title>
        <Cross onClick={onRemove} />
      </Header>
      <Prompt isFilled={!isNil(credit.type)} onClick={onPromptClick}>
        {t(`sentences.charge.credits.values.${credit.type || 'mortgage'}`)}
      </Prompt>
      {!isEditable ? (
        <CreditBoxSummary credit={credit} date={date} />
      ) : (
        <>
          {activeField > 0 && (
            <>
              {t('sentences.charge.credits.sentences.0')}
              <TrackedResizableField
                clearable
                events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'chargeCreditsAmount' } }}
                inputProps={{ placeholder: '500', type: 'integer' }}
                onChange={onChangeAmount}
                value={credit.amount?.toString() || ''}
              />
              {t('sentences.charge.credits.sentences.1')}
            </>
          )}
          {activeField > 1 && (
            <>
              {t('sentences.charge.credits.sentences.2')}
              <TrackedResizableField
                clearable
                events={{
                  eventName: EventName.SimulationFieldChanged,
                  eventPayload: { field: 'chargeCreditsEndDate' },
                }}
                inputProps={{ placeholder: placeholeDateFormat, type: 'date' }}
                message={!!message && t(`sentences.${message}`)}
                onChange={handleDateChange}
                state={state}
                value={date}
              />
            </>
          )}
        </>
      )}
      {activeField === 0 && isEditable && (
        <Choices
          choices={choices}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'chargeCreditType' } }}
          onChoiceClick={onChoiceClick}
        />
      )}
    </Container>
  )
}
