import { OccupationOthersStatus } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapOthersStatus: MapToChoices<OccupationOthersStatus[]> = (values, context) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]
  return values.map(value => ({
    key: value,
    isSelected: mortgagors?.[0].status === value,
    onClick: () => {
      clonedMortgagors[0].status = value
      setContext({ mortgagors: clonedMortgagors })
    },
    label: t(`sentences.situation.others.values.${value}`),
  }))
}
