import { getContributionProps } from '@pretto/app/src/Sentences/Contribution/Contribution/lib/helpers'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { getMinimumContribution, MinimumContribution } from '@pretto/app/src/Sentences/lib/contribution'
import { getContributionPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'
import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

export const ContributionPage: React.FC = () => {
  const context = useSentences()
  const { contribution, mortgagors, ownPropertyForSale, simulationType, setContext } = context
  const [minimumContribution, setMinimumContribution] = useState<MinimumContribution>()
  const [debouncedContribution] = useDebounce(contribution, 1000)

  const { tooltip, nextText } = getContributionProps(
    context,
    simulationType,
    debouncedContribution,
    minimumContribution?.contribution,
    mortgagors
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const minimumContribution = await getMinimumContribution(context)
      if (minimumContribution) setMinimumContribution(minimumContribution)
    })()
  }, [])

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'contribution' } }}>
      <Title>{t('sentences.contribution.title')}</Title>
      <div>
        {ownPropertyForSale
          ? t(`sentences.contribution.contribution.sentences.0`)
          : t(`sentences.contribution.contribution.sentences.1`)}
        {t('sentences.contribution.contribution.sentences.2')}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'contribution' } }}
          inputProps={{ autoFocus: true, placeholder: getContributionPlaceholder(context), type: 'integer' }}
          onChange={(value: string) => {
            setContext({ contribution: parseInt(value) })
          }}
          value={contribution?.toString() || ''}
        />
        {t('sentences.contribution.contribution.sentences.3')}.
      </div>
      {tooltip && <Message {...tooltip} />}
      {!isNil(contribution) && <ButtonNext>{nextText}</ButtonNext>}
    </TrackedView>
  )
}
