import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { Months, OccupationContractType } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'
import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

interface PrivateSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PrivateSentence: React.FC<PrivateSentenceProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { mortgagors, setContext } = context
  const comortgagor = mortgagors?.[1] ?? {}
  const [debouncedStartYear] = useDebounce(comortgagor.startYear, 1000)
  const clonedMortgagors = structuredClone(mortgagors)

  if (!clonedMortgagors) {
    return null
  }

  useEffect(() => {
    clonedMortgagors[1].status = undefined
    setContext({ mortgagors: clonedMortgagors })
  }, [])

  const handleYearChange = (value: string) => {
    const year = parseInt(value)
    const date = comortgagor.startDate || new Date()
    date.setFullYear(year)
    clonedMortgagors[1].startDate = date
    clonedMortgagors[1].startYear = value
    setContext({ mortgagors: clonedMortgagors })
  }

  return (
    <>
      {t('sentences.situation.in')}
      <Prompt
        onClick={() => {
          onSetActive(1)
        }}
        isFilled={!!comortgagor.contractType}
      >
        {t(`sentences.situation.contractType.values.${comortgagor.contractType || 'longTerm'}`)}
      </Prompt>
      {(activeField > 1 || !isNil(comortgagor.executiveRole)) &&
        comortgagor.contractType === OccupationContractType.LongTerm && (
          <>
            <Prompt
              onClick={() => {
                onSetActive(2)
              }}
              isFilled={!isNil(comortgagor.executiveRole)}
            >
              {t(
                `sentences.situation.private.longTerm.executive.values.${
                  comortgagor.executiveRole || isNil(comortgagor.executiveRole) ? 'executive' : 'nonExecutive'
                }`
              )}
            </Prompt>
          </>
        )}
      {(activeField > 2 || !isNil(comortgagor.trialPeriod)) &&
        comortgagor.contractType === OccupationContractType.LongTerm && (
          <>
            <Prompt
              onClick={() => {
                onSetActive(3)
              }}
              isFilled={!isNil(comortgagor.trialPeriod)}
            >
              {t(
                `sentences.situation.private.longTerm.trial.values.${
                  comortgagor.trialPeriod || isNil(comortgagor.trialPeriod) ? 'trial' : 'nonTrial'
                }`
              )}
            </Prompt>
          </>
        )}
      {(activeField > 1 || comortgagor.startMonth) && comortgagor.contractType === OccupationContractType.ShortTerm && (
        <>
          {t('sentences.situation.since')}
          <Prompt isFilled={!!comortgagor.startMonth}>
            {t(`sentences.months.${(comortgagor.startMonth as Months) || '0'}`)}
          </Prompt>
          <TrackedResizableField
            clearable
            events={{
              eventName: EventName.SimulationFieldChanged,
              eventPayload: { field: 'coMortgagorOccupationPrivateSince' },
            }}
            inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(context), type: 'date' }}
            message={t('sentences.situation.error')}
            onChange={handleYearChange}
            state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
            value={comortgagor.startYear || ''}
          />
        </>
      )}
    </>
  )
}
