import { ProjectType } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapPropertyTypeValues: MapToChoices<ProjectType[]> = (values, context) => {
  if (!context) return []
  const { projectType, setContext } = context

  return values.map(value => ({
    key: value,
    isSelected: projectType === value,
    onClick: () => {
      setContext({ projectType: value })
    },
    label: t(`sentences.introduction.propertyType.values.${value}`),
  }))
}
