import { Months } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapMonths: MapToChoices<Months[]> = (values, context) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]
  const startDate = (mortgagors?.[1].startDate as Date) ?? new Date()

  return values.map(value => ({
    key: value,
    isSelected: mortgagors?.[1].startMonth === value,
    onClick: () => {
      const date = startDate
      date.setUTCDate(1)
      date.setMonth(parseInt(value))
      clonedMortgagors[1].startDate = date
      clonedMortgagors[1].startMonth = value
      setContext({ mortgagors: clonedMortgagors })
    },
    label: t(`sentences.months.${value}`),
  }))
}
