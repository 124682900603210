import { mapPropertyTypeValues } from '@pretto/app/src/Sentences/Introduction/PropertyType/lib/mappers/mapPropertyTypeValues'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { ProjectType } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

export const PropertyTypePage: React.FC = () => {
  const context = useSentences()
  const { projectType } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && projectType))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'propertyType' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.introduction.propertyType.sentence')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!projectType}>
          {t(`sentences.introduction.propertyType.values.${projectType || 'old'}`)}
        </Prompt>
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapPropertyTypeValues(Object.values(ProjectType), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyType' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {projectType && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
