import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import translation from '@pretto/app/src/Sentences/config/translations/fr'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const WorksPage: React.FC = () => {
  const context = useSentences()
  const { worksPrice, hasWorks, setContext } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(
    hasWorks ? 1 : 0,
    Boolean(nextRoute && !isNil(hasWorks) && (!hasWorks || (hasWorks && worksPrice)))
  )

  const mapHasWorksValues = (values: [key: string, value: string][]) => {
    return values.map(([key, value]) => {
      return {
        key,
        value,
        isSelected: (key === 'yes' && hasWorks) || (key === 'no' && !hasWorks && !isNil(hasWorks)),
        onClick: () => {
          setContext({ hasWorks: key === 'yes' })
          setActiveField(key === 'yes' ? 1 : 0)
          if (key === 'no') goToNextRoute()
        },
        label: t(`sentences.property.works.values.${key as 'yes' | 'no'}`),
      }
    })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'works' } }}>
      <Title>{t('sentences.property.title')}</Title>
      <div>
        {t('sentences.property.works.sentences.0')}
        <Prompt isFilled={!isNil(hasWorks)} onClick={() => setActiveField(0)}>
          {t(`sentences.property.works.values.${hasWorks || isNil(hasWorks) ? 'yes' : 'no'}`)}
        </Prompt>
        {t('sentences.property.works.sentences.1')}
        {activeField > 0 && hasWorks && (
          <>
            {t('sentences.property.works.sentences.2')}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'propertyWorksPrice' } }}
              inputProps={{ autoFocus: activeField === 1, placeholder: '20 000', type: 'integer' }}
              onChange={(value: string) => {
                setContext({ worksPrice: parseInt(value) })
              }}
              value={worksPrice?.toString() || ''}
            />
            {t('sentences.property.works.sentences.3')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasWorksValues(Object.entries(translation.sentences.property.works.values))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertyWorks' } }}
        />
      )}
      {hasWorks && <Message>{t('sentences.tips.hasWorks')}</Message>}
      {((hasWorks && worksPrice) || (!isNil(hasWorks) && !hasWorks)) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
