import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { MaturityType } from '@pretto/app/src/Sentences/types/enums'
import { Maturity, ProjectKind } from '@pretto/app/src/types/gateway/enums'

import isNil from 'lodash/isNil'

const mapMaturityType = (maturity: Maturity): MaturityType | null => {
  switch (maturity) {
    case Maturity.OfferAccepted:
      return MaturityType.OfferAccepted
    case Maturity.OfferMade:
      return MaturityType.OfferMade
    case Maturity.SaleAgreementSigned:
      return MaturityType.SaleAgreementSigned
    case Maturity.SearchStarted:
      return MaturityType.SearchStarted
    case Maturity.UnderStudy:
      return MaturityType.UnderStudy
    case Maturity.Unspecified:
    default:
      return null
  }
}

export const mapMaturity: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  if (payload.purchase?.maturity) updatedContext.maturityType = mapMaturityType(payload.purchase.maturity)

  if (!isNil(payload.project_kind) && payload.project_kind !== ProjectKind.Renegotiation)
    updatedContext.hasPropertyPrice =
      payload.project_kind === ProjectKind.Purchase &&
      (!isNil(payload.purchase?.property_price) ||
        !isNil(payload.purchase?.land_price) ||
        !isNil(payload.purchase?.build_price))

  return {
    payload,
    context: updatedContext,
  }
}
