import { mapFrequencyValues } from '@pretto/app/src/Sentences/PropertySearch/Frequency/lib/mappers/mapFrequencyValues'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { FrequencyPropertySearch } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

export const FrequencyPage: React.FC = () => {
  const context = useSentences()
  const { propertySearchFrequency } = context

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'frequency' } }}>
      <Title>{t('sentences.propertySearch.title')}</Title>
      <div>
        {t('sentences.propertySearch.frequency.sentence')}
        <Prompt isFilled={!!propertySearchFrequency}>
          {t(`sentences.propertySearch.frequency.values.${propertySearchFrequency || 'real_time'}`)}
        </Prompt>
        .
      </div>
      <Choices
        choices={mapFrequencyValues(Object.values(FrequencyPropertySearch), context)}
        events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'propertySearchFrequency' } }}
      />
      {propertySearchFrequency && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
