import type { Income as ContextIncome, Revenue } from '@pretto/app/src/Sentences/types/compensation'
import { IncomePeriod, IncomeType, RevenueType } from '@pretto/app/src/Sentences/types/enums'
import { IncomeKind, Recurrence, Taxes } from '@pretto/app/src/types/gateway/enums'
import type { ExtraIncome, Maybe, Income as PayloadIncome } from '@pretto/app/src/types/gateway/schema'

import { validate } from 'uuid'

const mapRecurrenceToPeriod = (recurrence?: Maybe<Recurrence>): IncomePeriod => {
  switch (recurrence) {
    case Recurrence.Yearly:
      return IncomePeriod.Year
    case Recurrence.Monthly:
    default:
      return IncomePeriod.Month
  }
}

const mapTaxesToIncomeType = (taxes?: Maybe<Taxes>): IncomeType => {
  switch (taxes) {
    case Taxes.Before:
      return IncomeType.Gross
    case Taxes.After:
    default:
      return IncomeType.Net
  }
}

export const mapIncome = (income: PayloadIncome): ContextIncome => ({
  amount: income.amount ?? 0,
  period: mapRecurrenceToPeriod(income.period),
  type: mapTaxesToIncomeType(income.taxes),
})

export const mapBonus = (bonus: PayloadIncome): ContextIncome | null => {
  if (!bonus.amount && !bonus.period && !bonus.taxes) return null
  return {
    amount: bonus.amount ?? undefined,
    period: mapRecurrenceToPeriod(bonus.period),
    type: mapTaxesToIncomeType(bonus.taxes),
  }
}

const mapRevenueType = (incomeKind: IncomeKind): RevenueType | undefined => {
  switch (incomeKind) {
    case IncomeKind.ChildSupport:
      return RevenueType.ChildSupport
    case IncomeKind.ExpatriationBonus:
      return RevenueType.ExpatriationBonus
    case IncomeKind.FamilyAllowances:
      return RevenueType.FamilyAllowances
    case IncomeKind.ProfessionalBenefits:
      return RevenueType.ProfessionalBenefits
    case IncomeKind.Rent:
      return RevenueType.Rent
    case IncomeKind.InvalidityPension:
    case IncomeKind.MilitaryPension:
    case IncomeKind.Dividend:
      // TODO: Probably add them to our enum?
      return incomeKind as unknown as RevenueType
    default:
      return undefined
  }
}

const mapRevenue = (extraIncome: ExtraIncome & { id: string }): Revenue => ({
  amount: extraIncome.amount ?? undefined,
  type: extraIncome.kind ? mapRevenueType(extraIncome.kind) : undefined,
  id: extraIncome.id,
})

export const mapRevenues = (extraIncomes?: Maybe<ExtraIncome[]>): Revenue[] | undefined => {
  if (extraIncomes) {
    return extraIncomes
      .filter((income): income is ExtraIncome & { id: string } => validate(income.id ?? ''))
      .map(mapRevenue)
  }

  return undefined
}
