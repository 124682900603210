import { OccupationPrivateStatus } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const mapExecutiveValues: MapToChoices<OccupationPrivateStatus[]> = (values, context) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]
  return values.map(value => ({
    key: value,
    isSelected:
      (mortgagors?.[0].executiveRole && value === 'executive') ||
      (!isNil(mortgagors?.[0].executiveRole) && !mortgagors?.[0].executiveRole && value === 'nonExecutive'),
    onClick: () => {
      clonedMortgagors[0].executiveRole = value === 'executive'
      setContext({ mortgagors: clonedMortgagors })
    },
    label: t(`sentences.situation.private.longTerm.executive.values.${value}`),
  }))
}
