import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const mapBeenOwnerValues: MapToChoices<YesNo[]> = (values, context) => {
  if (!context) return []
  const { firstTimeBuyer, setContext } = context

  return values.map(value => ({
    key: value,
    isSelected: !isNil(firstTimeBuyer) && (firstTimeBuyer && value === YesNo.No || !firstTimeBuyer && value === YesNo.Yes),
    onClick: () => {
      setContext({ firstTimeBuyer: value === YesNo.No })
    },
    label: t(`sentences.ptz.beenOwner.values.${value as 'yes' | 'no'}`),
  }))
}
