import { WantsRateAlertPage } from '@pretto/app/src/Sentences/RateAlert/WantsRateAlert/Containers/WantsRateAlertPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const RateAlertController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(purchase|renegotiation)/rate-alert/wantsRateAlert')}
        component={WantsRateAlertPage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
