import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { ProjectType, SimulationType, Usage } from '@pretto/app/src/Sentences/types/enums'
import { Usage as PayloadUsage, ProjectKind, PropertyKind } from '@pretto/app/src/types/gateway/enums'
import { Project } from '@pretto/app/src/types/gateway/schema'

import { isNil } from 'ramda'

const mapProjectType = (projectKind: PropertyKind): ProjectType | undefined => {
  switch (projectKind) {
    case PropertyKind.Construction:
      return ProjectType.Construction
    case PropertyKind.LandConstruction:
      return ProjectType.LandConstruction
    case PropertyKind.New:
      return ProjectType.New
    case PropertyKind.Old:
      return ProjectType.Old
    case PropertyKind.Vefa:
      return ProjectType.VEFA
    case PropertyKind.Land:
    case PropertyKind.Scpi:
    case PropertyKind.Ccmi:
    default:
      return undefined
  }
}

const mapUsage = (usage: PayloadUsage): Usage | null => {
  switch (usage) {
    case PayloadUsage.PrimaryResidence:
      return Usage.Primary
    case PayloadUsage.RentalInvestment:
      return Usage.Rental
    case PayloadUsage.SecondaryResidence:
      return Usage.Secondary
    default:
      return null
  }
}

const mapSimulationType = (projectKind: ProjectKind, payload: Project): SimulationType | null => {
  if (payload.purchase?.specific_amounts.some(({ label }) => label === 'Montant de la soulte')) {
    return SimulationType.Buyout
  }

  switch (projectKind) {
    case ProjectKind.Capacity:
      return SimulationType.Capacity
    case ProjectKind.Purchase:
      if (!payload.purchase?.property_price && !payload.purchase?.land_price && !payload.purchase?.build_price)
        return SimulationType.Capacity
      return SimulationType.Purchase
    case ProjectKind.Renegotiation:
      return SimulationType.Renegotiation
    default:
      return null
  }
}

export const mapProject: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  if (payload.project_kind) updatedContext.simulationType = mapSimulationType(payload.project_kind, payload)

  if (payload.good?.property_kind) updatedContext.projectType = mapProjectType(payload.good.property_kind)
  if (payload.good?.localisation?.city) updatedContext.propertyCity = payload.good.localisation.city
  if (payload.good?.localisation?.country) updatedContext.propertyCountry = payload.good.localisation.country
  if (payload.good?.localisation?.zipcode) updatedContext.propertyZipcode = payload.good.localisation.zipcode
  if (payload.good?.usage) updatedContext.usage = mapUsage(payload.good.usage)
  if (payload.good?.dpe) updatedContext.dpe = payload.good.dpe

  if (payload.purchase?.property_price) updatedContext.propertyPrice = payload.purchase.property_price
  if (payload.purchase?.agreement_sign_date)
    updatedContext.agreementSignedDate = new Date(payload.purchase.agreement_sign_date)
  if (payload.purchase?.expected_rental_income)
    updatedContext.expectedRentalIncome = payload.purchase.expected_rental_income
  if (payload.purchase?.works_price) {
    updatedContext.hasWorks = true
    updatedContext.worksPrice = payload.purchase.works_price
  } else {
    updatedContext.hasWorks = false
  }
  if (payload.purchase?.land_price) updatedContext.landPrice = payload.purchase.land_price
  if (payload.purchase?.build_price) updatedContext.constructionPrice = payload.purchase.build_price

  const buyoutAmount = payload.purchase?.specific_amounts.find(({ label }) => label?.includes('Montant de la soulte'))
    ?.amount

  if (buyoutAmount) updatedContext.buyoutAmount = buyoutAmount

  const buyoutCreditRemainingPrincipal = payload.purchase?.specific_amounts.find(
    ({ label }) => label?.includes('Montant du CRD')
  )?.amount

  if (buyoutCreditRemainingPrincipal) updatedContext.buyoutCreditRemainingPrincipal = buyoutCreditRemainingPrincipal
  if (buyoutAmount) updatedContext.hasBuyoutCredit = buyoutCreditRemainingPrincipal !== undefined

  updatedContext.hasEcoPtz =
    !isNil(payload.eco_works) && Object.values(payload.eco_works).some(ecoWork => ecoWork === true)

  return {
    payload,
    context: updatedContext,
  }
}
