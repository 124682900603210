import { BonusPage } from '@pretto/app/src/Sentences/ComortgagorIncome/Bonus/Containers/BonusPage'
import { RevenuePage } from '@pretto/app/src/Sentences/ComortgagorIncome/Revenue/Containers/RevenuePage'
import { SalaryPage } from '@pretto/app/src/Sentences/ComortgagorIncome/Salary/Containers/SalaryPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const ComortgagorIncomeController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/income-comortgagor/salary')}
        component={SalaryPage}
      />

      <Route
        path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/income-comortgagor/bonus')}
        component={BonusPage}
      />

      <Route
        path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/income-comortgagor/revenue')}
        component={RevenuePage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
