import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const ComortgagorChildSupportPage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const clonedMortgagors = structuredClone(mortgagors)
  const { goToNextRoute, nextRoute } = useRoutesGraph()

  const getIsFilled = () => {
    if (!nextRoute) return false
    if (isNil(clonedMortgagors?.[1].paysChildSupport)) return false
    if (clonedMortgagors?.[1].paysChildSupport && isNil(clonedMortgagors?.[1].childSupport)) return false
    return true
  }

  const [activeField, setActiveField] = useActiveField(clonedMortgagors?.[1].paysChildSupport ? 1 : 0, getIsFilled())

  if (!clonedMortgagors?.length) {
    return null
  }

  const paysComortgagorChildSupport = clonedMortgagors[1].paysChildSupport

  const mapHasComortgagorChildSupport = (values: YesNo[]) => {
    return values.map(value => {
      return {
        key: value,
        value,
        isSelected:
          (value === 'yes' && paysComortgagorChildSupport) ||
          (value === 'no' && !paysComortgagorChildSupport && !isNil(paysComortgagorChildSupport)),
        onClick: () => {
          clonedMortgagors[1].paysChildSupport = value === 'yes'
          setContext({ mortgagors: clonedMortgagors })
          setActiveField(value === 'yes' ? 1 : 0)
          if (value === 'no') goToNextRoute()
        },
        label: t(`sentences.charge.childSupport.comortgagor.values.${value}`),
      }
    })
  }

  const saveAmount = (value: string) => {
    clonedMortgagors[1].childSupport = parseInt(value)
    setContext({ mortgagors: clonedMortgagors })
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'comortgagorChildSupport' } }}>
      <Title>{t('sentences.charge.title')}</Title>
      <div>
        {t('sentences.charge.childSupport.comortgagor.sentence')}
        <Prompt isFilled={!isNil(paysComortgagorChildSupport)} onClick={() => setActiveField(0)}>
          {t(
            `sentences.charge.childSupport.comortgagor.values.${
              paysComortgagorChildSupport || isNil(paysComortgagorChildSupport) ? 'yes' : 'no'
            }`
          )}
        </Prompt>
        {t('sentences.charge.childSupport.sentences.1')}
        {activeField > 0 && paysComortgagorChildSupport && (
          <>
            {t('sentences.charge.childSupport.sentences.2')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorChildSupport' },
              }}
              inputProps={{ autoFocus: activeField === 1, placeholder: '300', type: 'integer' }}
              onChange={saveAmount}
              value={mortgagors?.[1].childSupport?.toString() || ''}
            />
            {t('sentences.charge.childSupport.sentences.3')}
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasComortgagorChildSupport(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorChildSupport' } }}
        />
      )}
      <ButtonNext>{t('sentences.next')}</ButtonNext>
    </TrackedView>
  )
}
