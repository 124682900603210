import { BonusPage } from '@pretto/app/src/Sentences/Income/Bonus/Containers/BonusPage'
import { RevenuePage } from '@pretto/app/src/Sentences/Income/Revenue/Containers/RevenuePage'
import { SalaryPage } from '@pretto/app/src/Sentences/Income/Salary/Containers/SalaryPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const IncomeController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/income/salary')}
        component={SalaryPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/income/bonus')}
        component={BonusPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/income/revenue')}
        component={RevenuePage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
