import { ExpatriatePage } from '@pretto/app/src/Sentences/Occupation/Expatriate/Containers/ExpatriatePage'
import { SectorPage } from '@pretto/app/src/Sentences/Occupation/Sector/Containers/SectorPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const OccupationController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/occupation/sector')}
        component={SectorPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase)/occupation/expatriate')}
        component={ExpatriatePage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
