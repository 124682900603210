import { useEffect, useRef, useState } from 'react'

/**
 * This useState wrapper exposes a function that returns a promise that resolves
 * when all setState operations are finished.
 * It is useful when you want to perform a setState operation followed by another operation.
 *
 * Exemple:
 * const [value, setValue, waitForOngoingOperations] = useState(2)
 *
 * ...
 * setValue(3)
 * const newValue = await waitForOngoingOperations()
 */
export const useStateOperations = <C>(
  initialState: C
): [C, React.Dispatch<React.SetStateAction<C>>, () => Promise<C>] => {
  const isOperationInProgress = useRef(false)
  const nextOperation = useRef<((value: C) => void) | null>(null)

  const [state, setState] = useState(initialState)

  useEffect(() => {
    isOperationInProgress.current = false
    nextOperation.current?.(state)
    nextOperation.current = null
  }, [state])

  const operatingSetState: React.Dispatch<React.SetStateAction<C>> = state => {
    isOperationInProgress.current = true
    setState(state)
  }

  const waitForOngoingOperations = () => {
    if (!isOperationInProgress.current) {
      return Promise.resolve(state)
    }

    return new Promise<C>(resolve => {
      nextOperation.current = resolve
    })
  }

  return [state, operatingSetState, waitForOngoingOperations]
}
