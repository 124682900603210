import { ChildSupportPage } from '@pretto/app/src/Sentences/Charge/ChildSupport/Containers/ChildSupportPage'
import { ComortgagorChildSupportPage } from '@pretto/app/src/Sentences/Charge/ComortgagorChildSupport/Containers/ChildSupportPage'
import { CreditsPage } from '@pretto/app/src/Sentences/Charge/Credits/Containers/CreditsPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const ChargeController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/credits/credits')}
        component={CreditsPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/credits/childSupport')}
        component={ChildSupportPage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/credits/comortgagorChildSupport')}
        component={ComortgagorChildSupportPage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
