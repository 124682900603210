import { g } from '@pretto/bricks/components/layout'

import { ButtonChoice } from '@pretto/zen/reveal/atoms/buttons/buttonChoice/ButtonChoice'

import { ResizableField } from '@pretto/app/src/Sentences/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { DividerWithMarginBottom } from '@pretto/app/src/Sentences/components/Styled/Divider/Divider'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedButtonInline } from '@pretto/app/src/Sentences/containers/TrackedButtonInline/TrackedButtonInline'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { Result as CityResult, useCityField } from '@pretto/app/src/Sentences/lib/useCityField'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import { useState } from 'react'
import styled from 'styled-components'

const Cities = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: ${g(2)};
  }
`

/** really big, gotta separate it and maybe use useReducer instead of useState */
export const LocalisationsPage: React.FC = () => {
  const context = useSentences()
  const { propertyCity, propertyZipcode, propertySearchLocalisations, setContext } = context
  const clonedLocalisations = structuredClone(propertySearchLocalisations)
  const { fieldProps: cityFieldProps, results: cityResults } = useCityField()
  const [visibleResults, setVisibleResults] = useState(false)
  const [values, setValues] = useState<string[]>(
    propertySearchLocalisations.map(({ city, zipcode }) => (city && zipcode ? `${city} (${zipcode})` : ''))
  )
  const [activeField, setActiveField] = useActiveField(
    0,
    propertySearchLocalisations.every(localisation => localisation.city && localisation.zipcode)
  )

  const handleClickOnCity = (value: CityResult['value']) => {
    const localisation = {
      city: value.city,
      zipcode: value.zipcode,
    }
    clonedLocalisations[activeField] = localisation

    setValues(values => {
      const clonedValues = [...values]
      clonedValues[activeField] = `${value.city} (${value.zipcode})`
      return clonedValues
    })
    setVisibleResults(false)
    setContext({ propertySearchLocalisations: clonedLocalisations })
  }

  const mapCityValues: MapToChoices<CityResult[]> = values =>
    values.map(({ label, value }) => ({
      key: value.zipcode,
      isSelected: propertyCity === value.city && propertyZipcode === value.zipcode,
      onClick: () => handleClickOnCity(value),
      label,
    }))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'localisationsPropertySearch' } }}>
      <Title>{t('sentences.propertySearch.title')}</Title>
      <div>
        {t('sentences.propertySearch.localisations.sentence')}
        {propertySearchLocalisations.map((localisation, index) => {
          const handleChange = (value: string) => {
            setVisibleResults(true)
            cityFieldProps.onChange(value)
            setValues(values => {
              const clonedValues = [...values]
              clonedValues[index] = value
              return clonedValues
            })
          }

          const handleClear = () => {
            clonedLocalisations.splice(index, 1)
            setValues(values => {
              const clonedValues = [...values]
              clonedValues.splice(index, 1)
              return clonedValues
            })
            setContext({ propertySearchLocalisations: clonedLocalisations })
          }

          return (
            <>
              <ResizableField
                clearable
                inputProps={{ autoFocus: true, placeholder: 'Paris' }}
                onChange={handleChange}
                onClear={handleClear}
                onClick={() => {
                  setActiveField(index)
                }}
                value={values[index]}
              />
              {index < propertySearchLocalisations.length - 1 && ', '}
            </>
          )
        })}
        .{' '}
        {propertySearchLocalisations.every(localisation => localisation.city && localisation.zipcode) && (
          <TrackedButtonInline
            events={{
              eventName: EventName.SimulationElementClicked,
              eventPayload: { element: 'propertySearchLocalisationsAddCity' },
            }}
            onClick={() => {
              clonedLocalisations.push({})
              setValues(values => [...values, ''])
              setContext({ propertySearchLocalisations: clonedLocalisations })
              setActiveField(clonedLocalisations.length - 1)
            }}
          >
            {t('sentences.propertySearch.localisations.addCity')}
          </TrackedButtonInline>
        )}
      </div>
      <DividerWithMarginBottom />
      <Cities>
        {propertySearchLocalisations.map(({ city, zipcode }) => {
          return city && zipcode ? <ButtonChoice isSelected={true}>{`${city} (${zipcode})`}</ButtonChoice> : null
        })}
        {cityResults && visibleResults && activeField !== Infinity && (
          <Choices
            choices={mapCityValues(cityResults)}
            events={{
              eventName: EventName.SimulationChoiceClicked,
              eventPayload: { choice: 'localisationsPropertySearch' },
            }}
          />
        )}
      </Cities>
      {propertySearchLocalisations && propertySearchLocalisations.length && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
