import { isNotSolo } from '@pretto/app/src/Sentences/lib/validators/context'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { IncomePeriod, IncomeType } from '@pretto/app/src/Sentences/types/enums'

import round from 'lodash/round'

type Placeholder = (context: ProjectContext) => string

export const getContributionPlaceholder: Placeholder = context => {
  let amount = 0
  const mortgagorSalary = context.mortgagors?.[0]?.income
  const comortgagorSalary = context.mortgagors?.[1]?.income
  if (mortgagorSalary && mortgagorSalary.amount) {
    amount += mortgagorSalary.period === IncomePeriod.Month ? mortgagorSalary.amount : mortgagorSalary.amount / 12
  }
  if (isNotSolo(context) && comortgagorSalary && comortgagorSalary.amount) {
    amount += comortgagorSalary.period === IncomePeriod.Month ? comortgagorSalary.amount : comortgagorSalary.amount / 12
  }
  const placeholder = Math.round((amount * 8) / 1000) * 1000
  return Math.round(placeholder).toLocaleString('fr')
}

export const getJobStartYearPlaceholder: Placeholder = () => (new Date().getFullYear() - 5).toString()

export const getSavingsPlaceholder: Placeholder = context => {
  const totalMonthlyIncomeBeforeTaxes =
    context.mortgagors?.reduce((total, { income }) => {
      if (!income || !income.amount) return total
      const netAmount = income.type === IncomeType.Gross ? income.amount * 0.8 : income.amount
      const monthlyAmount = income.period === IncomePeriod.Year ? netAmount / 12 : netAmount
      return total + monthlyAmount
    }, 0) || 0

  return round(totalMonthlyIncomeBeforeTaxes * 3, -4).toString()
}
