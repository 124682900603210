import { OccupationContractType } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapContractTypeValues: MapToChoices<OccupationContractType[]> = (values, context) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}, {}]

  return values.map(value => ({
    key: value,
    isSelected: mortgagors?.[1].contractType === value,
    onClick: () => {
      clonedMortgagors[1].contractType = value
      setContext({ mortgagors: clonedMortgagors })
    },
    label: t(`sentences.situation.contractType.values.${value}`),
  }))
}
