import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import isNil from 'lodash/isNil'

export const mapPaidOffValues: MapToChoices<YesNo[]> = (values, context) => {
  if (!context) return []
  const { ownPropertyPaidOff, setContext } = context

  return values.map(value => ({
    key: value,
    isSelected: !isNil(ownPropertyPaidOff) && (ownPropertyPaidOff && value === YesNo.Yes || !ownPropertyPaidOff && value === YesNo.No),
    onClick: () => {
      setContext({ ownPropertyPaidOff: value === YesNo.Yes })
    },
    label: t(`sentences.ptz.beenOwner.values.${value as 'yes' | 'no'}`),
  }))
}
