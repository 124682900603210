import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { formatFloat } from '@pretto/app/src/Sentences/lib/float'
import { t } from '@pretto/app/src/lib/i18n'

import { useState } from 'react'

export const RatePage: React.FC = () => {
  const context = useSentences()
  const { rate, setContext } = context
  const [displayedRate, setDisplayedRate] = useState(rate?.toString() || '')

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'rate' } }}>
      <Title>{t('sentences.loan.title')}</Title>
      <div>
        {t(`sentences.loan.rate.sentences.0`)}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'loanRate' } }}
          inputProps={{ autoFocus: true, placeholder: '2,5', type: 'number' }}
          onChange={(value: string) => {
            const newRate = formatFloat(value)
            setDisplayedRate(newRate)
            setContext({ rate: parseFloat(newRate) })
          }}
          value={displayedRate}
        />
        {t(`sentences.loan.rate.sentences.1`)}.
      </div>
      {!!displayedRate && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
