import { ResizableField } from '@pretto/app/src/Sentences/components/ResizableField/ResizableField'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { Bank, useBankField } from '@pretto/app/src/Sentences/lib/useBankField'
import { InsuranceType } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'
import BANKS from '@pretto/config/banks.json'

const mapInsuranceTypeValues: MapToChoices<InsuranceType[]> = (values, context) => {
  if (!context) return []

  const { insuranceType, setContext } = context

  return values.map(value => ({
    key: value,
    isSelected: insuranceType === value,
    onClick: () => {
      setContext({ insuranceType: value })
    },
    label: t(`sentences.loan.bank.values.${value}`),
  }))
}

export const BankPage: React.FC = () => {
  const context = useSentences()
  const { bank, insuranceType, setContext } = context
  const { goToNextRoute } = useRoutesGraph()
  const { fieldProps: bankFieldProps, results: bankResults } = useBankField(
    BANKS.find(elem => elem.value === bank)?.label || ''
  )

  const [activeField, setActiveField] = useActiveField(bank ? 1 : 0, !!insuranceType)

  const mapBankValues: MapToChoices<Bank[]> = values =>
    values.map(({ label, value }) => ({
      key: value,
      isSelected: bank === value,
      onClick: () => {
        bankFieldProps.onChange(label)
        setContext({ bank: value })
      },
      label,
    }))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'bank' } }}>
      <Title>{t('sentences.loan.title')}</Title>
      <div>
        {t('sentences.loan.bank.sentences.0')}
        <ResizableField
          {...bankFieldProps}
          clearable
          inputProps={{ autoFocus: true, placeholder: 'Une banque', spellCheck: false }}
          onClick={() => {
            setActiveField(0)
          }}
        />
        {activeField > 0 && (
          <>
            {t('sentences.loan.bank.sentences.1')}
            <Prompt isFilled={!!insuranceType} onClick={() => setActiveField(1)}>
              {t(`sentences.loan.bank.values.${insuranceType || 'bankGroupInsurance'}`)}
            </Prompt>
          </>
        )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapBankValues(bankResults)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'bank' } }}
          onChoiceClick={() => setActiveField(1)}
        />
      )}
      {activeField === 1 && (
        <Choices
          choices={mapInsuranceTypeValues(Object.values(InsuranceType), context)}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'insuranceType' } }}
          onChoiceClick={() => {
            goToNextRoute()
          }}
        />
      )}
      {activeField > 1 && insuranceType && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
