import { OthersChoices } from '@pretto/app/src/Sentences/Occupation/Sector/Containers/SectorPage/Others/choices'
import { OthersSentence } from '@pretto/app/src/Sentences/Occupation/Sector/Containers/SectorPage/Others/sentence'
import { checkSeniorityYearIsFilled } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsFilled'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import {
  isCDD as isMortgagorCSS,
  isEarlyBusinessOwner as isMortgagorEarlyBusinessOwner,
  isOnTrial as isMortgagorOnTrial,
} from '@pretto/app/src/Sentences/lib/contract'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { isOccupationFilled } from '@pretto/app/src/Sentences/lib/validators/context'
import { OccupationType } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import { useEffect } from 'react'

import { PrivateChoices } from './Private/choices'
import { PrivateSentence } from './Private/sentence'
import { PublicChoices } from './Public/choices'
import { PublicSentence } from './Public/sentence'

export const SectorPage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const clonedMortgagors = mortgagors ? structuredClone(mortgagors) : [{}]
  const mainMortgagor = mortgagors?.[0] ?? {}
  const { nextRoute } = useRoutesGraph()

  const isOnTrial = isMortgagorOnTrial(mainMortgagor)
  const isCDD = isMortgagorCSS(mainMortgagor)
  const isEarlyBusinessOwner = isMortgagorEarlyBusinessOwner(mainMortgagor)

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && isOccupationFilled(context)))

  useEffect(() => {
    if (mortgagors?.[0].startYear && mortgagors?.[0].startMonth) {
      const clonedMortgagors = structuredClone(mortgagors) ?? [{}]
      clonedMortgagors[0].startDate = new Date(
        Number(mortgagors?.[0].startYear),
        Number(mortgagors?.[0].startMonth) + 1
      )
      setContext({ mortgagors: clonedMortgagors })
    }
  }, [mortgagors?.[0].startYear, mortgagors?.[0].startMonth])

  const mapSectorValues: MapToChoices<OccupationType[]> = values => {
    return values.map(value => ({
      key: value,
      isSelected: mainMortgagor.occupationType === value,
      onClick: () => {
        clonedMortgagors[0].occupationType = value
        setContext({ mortgagors: clonedMortgagors })
        setActiveField(1)
      },
      label: t(`sentences.situation.sector.values.${value}`),
    }))
  }

  const handleActiveField = (field: number) => {
    setActiveField(field)
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'sector' } }}>
      <Title>{t('sentences.situation.title')}</Title>
      <div>
        {t('sentences.situation.sector.sentence')}
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!!mainMortgagor.occupationType}
        >
          {t(`sentences.situation.sector.values.${mainMortgagor.occupationType || OccupationType.Private}`)}
        </Prompt>
        {activeField > 0 && mainMortgagor.occupationType === OccupationType.Private && (
          <PrivateSentence activeField={activeField} onSetActive={handleActiveField} />
        )}
        {activeField > 0 && mainMortgagor.occupationType === OccupationType.Public && (
          <PublicSentence activeField={activeField} onSetActive={handleActiveField} />
        )}
        {activeField > 0 &&
          (mainMortgagor.occupationType === OccupationType.Others ||
            mainMortgagor.occupationType === OccupationType.Medical) && (
            <OthersSentence activeField={activeField} onSetActive={handleActiveField} />
          )}
        .
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapSectorValues(Object.values(OccupationType))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'occupationType' } }}
        />
      )}
      {activeField > 0 && mainMortgagor.occupationType === OccupationType.Private && (
        <PrivateChoices activeField={activeField} onSetActive={handleActiveField} />
      )}
      {activeField > 0 && mainMortgagor.occupationType === OccupationType.Public && (
        <PublicChoices activeField={activeField} onSetActive={handleActiveField} />
      )}
      {activeField > 0 &&
        (mainMortgagor.occupationType === OccupationType.Others ||
          mainMortgagor.occupationType === OccupationType.Medical) && (
          <OthersChoices activeField={activeField} onSetActive={handleActiveField} />
        )}
      {isOnTrial && <Message>{t('sentences.tips.occupation.trialPeriod.mortgagor')}</Message>}
      {isCDD && <Message>{t('sentences.tips.occupation.shortTerm.mortgagor')}</Message>}
      {isEarlyBusinessOwner && <Message>{t('sentences.tips.occupation.earlyBusinessOwner.mortgagor')}</Message>}
      {isOccupationFilled(context) && checkSeniorityYearIsFilled(mainMortgagor) && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
