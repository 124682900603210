import { getCenterWindowCoordinates } from '@pretto/bricks/core/utility/window/getCenterWindowCoordinates'

import { CONTEXT_STORAGE_KEY } from '@pretto/app/src/Sentences/lib/localStorage'

import IdTokenVerifier from 'idtoken-verifier'
import { v4 as uuidv4 } from 'uuid'
import { open } from 'winchan'

import { getItem, removeItem, setItem, setStorageType } from '../../config/itemStorage'

const AUTH_TOKEN = 'auth_token'
const REFRESH_TOKEN = 'refresh_token'
const VISITOR_UUID = 'UUID'

// getters

export const getAuthToken = () => sessionStorage.getItem(AUTH_TOKEN) || localStorage.getItem(AUTH_TOKEN) || null

export const getRefreshToken = () => getItem(REFRESH_TOKEN) || null

export const getAccountId = (authToken = getAuthToken()) => (authToken ? decodeToken(authToken).sub : null)

export const getUserEmail = (authToken = getAuthToken()) => (authToken ? decodeToken(authToken).email : null)

export const getUserEmailVerified = (authToken = getAuthToken()) =>
  authToken ? decodeToken(authToken).email_verified : null

export const getUserProfile = (authToken = getAuthToken()) => (authToken ? decodeToken(authToken).source : null)

export const getUserVia = (authToken = getAuthToken()) => (authToken ? decodeToken(authToken).via : null)

export const getVisitorUUID = () => {
  const uuid = getItem(VISITOR_UUID)

  if (!uuid) {
    const uuid = uuidv4()

    setItem(VISITOR_UUID, uuid)

    return uuid
  }

  return uuid
}

export const getFrontHMAC = (authToken = getAuthToken()) => {
  return authToken ? decodeToken(authToken).front_chat_secret : null
}

export const getR3 = (authToken = getAuthToken()) => {
  return authToken ? decodeToken(authToken).appointment_kind : null
}

// actions

export const authorize = (state, features = { height: 600, width: 500 }) =>
  new Promise((resolve, reject) => {
    const allFeatures = {
      ...features,
      ...getCenterWindowCoordinates(features),
    }

    const flattenFeatures = Object.entries(allFeatures)
      .reduce((previous, [key, value]) => {
        return [...previous, `${key}=${value}`]
      }, [])
      .join(',')

    open(
      {
        origin: process.env.CLIENT_HOST,
        relay_url: `${process.env.CLIENT_HOST}/api/oauth/relay.html`,
        url: `${
          process.env.NODE_ENV === 'development' ? process.env.CLIENT_HOST : ''
        }/api/oauth/authorize?state=${state}`,
        window_features: flattenFeatures,
      },
      (error, response) => {
        if (error) {
          return reject(error)
        }

        if (response.error) {
          return reject(response.error)
        }

        if (response.data.state !== state) {
          return reject('Mismatching states')
        }

        return resolve(response.data.data)
      }
    )
  })

export const decodeToken = token => {
  const decoder = new IdTokenVerifier()
  const { payload } = decoder.decode(token)

  return payload
}

export const flushTokens = () => {
  removeItem(AUTH_TOKEN, true)
  removeItem(REFRESH_TOKEN, true)
  removeItem(CONTEXT_STORAGE_KEY, true)

  setItem(VISITOR_UUID, uuidv4())
}

export const isLoggedIn = () => !!getAuthToken() || !!getRefreshToken()

export const persistTokens = ({ accessToken, refreshToken = null }) => {
  const userProfile = getUserProfile(accessToken)

  setStorageType(userProfile)

  setItem(AUTH_TOKEN, accessToken)
  setItem(REFRESH_TOKEN, refreshToken)
}
