import { ErrorCodes } from '@pretto/app/src/Sentences/types/errors'

import { createContext, PropsWithChildren, useContext, useState } from 'react'

type ErrorsContextInterface = {
  errors: ErrorCodes[]
  setErrors: React.Dispatch<React.SetStateAction<ErrorCodes[]>>
}

export const ErrorsContext = createContext<ErrorsContextInterface>({} as ErrorsContextInterface)

export const ErrorsContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [errors, setErrors] = useState<ErrorCodes[]>([])

  return (
    <ErrorsContext.Provider
      value={{
        errors,
        setErrors,
      }}
    >
      {children}
    </ErrorsContext.Provider>
  )
}

export const useErrors = (): [ErrorCodes[], React.Dispatch<React.SetStateAction<ErrorCodes[]>>] => {
  const { errors, setErrors } = useContext(ErrorsContext)

  return [errors, setErrors]
}
