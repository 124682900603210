import { LOCAL_STORAGE_ALERT, LOCAL_STORAGE_IMMO } from '@pretto/app/src/Sentences/config/storage/storage'
import { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { getItem } from '@pretto/app/src/config/itemStorage'

export const CONTEXT_STORAGE_KEY = 'app-pretto-context'

export const getStoredContext = (projectIdUser: string): Partial<ProjectContext> | undefined => {
  const item = getItem(CONTEXT_STORAGE_KEY)
  if (item) {
    const context = JSON.parse(item, (key, value) => {
      if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}/)) {
        return new Date(value)
      }
      return value
    }) as ProjectContext
    if (context.id !== projectIdUser) return undefined
    if (getItem(LOCAL_STORAGE_ALERT)) context.isNotVisibleRateAlert = true
    if (getItem(LOCAL_STORAGE_IMMO)) context.isNotVisiblePropertySearch = true
    return context
  }
}

export const storeContext = (context: Partial<ProjectContext>): void =>
  window.localStorage.setItem(CONTEXT_STORAGE_KEY, JSON.stringify(context))
