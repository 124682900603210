import { isLessThan } from '@pretto/app/src/Sentences/lib/date'
import {
  OccupationContractType,
  OccupationOthersStatus,
  OccupationType,
} from '@pretto/app/src/Sentences/types/enums'
import type { Mortgagor } from '@pretto/app/src/Sentences/types/mortgagor'

export const isOnTrial = (mortgagor: Mortgagor): boolean => mortgagor.trialPeriod === true

export const isCDD = (mortgagor: Mortgagor): boolean =>
  mortgagor.contractType === OccupationContractType.ShortTerm && mortgagor.occupationType === OccupationType.Private

const getThreeYearsAgoDate = (): Date => {
  const threeYearsAgo = new Date()
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3)
  return threeYearsAgo
}

export const isEarlyBusinessOwner = (mortgagor: Mortgagor): boolean =>
  mortgagor.occupationType === OccupationType.Others &&
  mortgagor.status === OccupationOthersStatus.BusinessOwner &&
  !!mortgagor.startDate &&
  isLessThan(getThreeYearsAgoDate(), mortgagor.startDate)
