import * as v from '@pretto/app/src/Sentences/lib/validators/context'
import { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { Edge, Graph, Route } from '@pretto/app/src/lib/graph/Graph'

import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'

// Introduction
const structureRoute = new Route<ProjectContext>('structure', '/project/capacity/introduction/structure')
const propertyUsageRoute = new Route<ProjectContext>('propertyUsage', '/project/capacity/introduction/propertyUsage')
const propertyTypeRoute = new Route<ProjectContext>('propertyType', '/project/capacity/introduction/propertyType')
const sciTypeRoute = new Route<ProjectContext>('sciType', '/project/capacity/introduction/sciType')
const sciAssociationRoute = new Route<ProjectContext>('sciAssociation', '/project/capacity/introduction/sciAssociation')

export const capacityGraph = new Graph<ProjectContext>('capacityGraph', structureRoute)

capacityGraph.addRoute(propertyUsageRoute)
capacityGraph.addRoute(propertyTypeRoute)
capacityGraph.addRoute(sciTypeRoute)
capacityGraph.addRoute(sciAssociationRoute)

capacityGraph.addEdge(new Edge<ProjectContext>(structureRoute, sciTypeRoute, v.isSCI))
capacityGraph.addEdge(new Edge<ProjectContext>(sciTypeRoute, sciAssociationRoute, v.isIR))
capacityGraph.addEdge(new Edge<ProjectContext>(sciAssociationRoute, propertyUsageRoute, v.isAssociationSimple))

capacityGraph.addEdge(
  new Edge<ProjectContext>(structureRoute, propertyUsageRoute, allPass([v.hasStructure, v.isNotSCI]))
)

capacityGraph.addEdge(new Edge<ProjectContext>(propertyUsageRoute, propertyTypeRoute, v.hasPropertyUsage))

// Lodging
const ageChildrenRoute = new Route<ProjectContext>('ageChildren', '/project/capacity/lodging/ageChildren')
const liveTogetherRoute = new Route<ProjectContext>('liveTogether', '/project/capacity/lodging/liveTogether')
const addressRoute = new Route<ProjectContext>('address', '/project/capacity/lodging/address')
const situationRoute = new Route<ProjectContext>('situation', '/project/capacity/lodging/situation')

capacityGraph.addRoute(ageChildrenRoute)
capacityGraph.addRoute(liveTogetherRoute)
capacityGraph.addRoute(addressRoute)
capacityGraph.addRoute(situationRoute)

capacityGraph.addEdge(new Edge<ProjectContext>(propertyTypeRoute, ageChildrenRoute, v.hasPropertyType))

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    ageChildrenRoute,
    liveTogetherRoute,
    allPass([v.isNotSolo, v.hasAge, v.hasCoMortgagorAge, v.hasChildrenNumber])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber, v.isSolo]))
)

capacityGraph.addEdge(new Edge<ProjectContext>(liveTogetherRoute, addressRoute, v.hasLiveTogetherCompleted))
capacityGraph.addEdge(new Edge<ProjectContext>(addressRoute, situationRoute, v.hasAddress))

// Occupation
const expatriateRoute = new Route<ProjectContext>('expatriate', '/project/capacity/occupation/expatriate')
const sectorRoute = new Route<ProjectContext>('sector', '/project/capacity/occupation/sector')

capacityGraph.addRoute(expatriateRoute)
capacityGraph.addRoute(sectorRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(sectorRoute, expatriateRoute, allPass([v.isInternational, v.isOccupationFilled]))
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(situationRoute, sectorRoute, allPass([v.hasSituationType, v.hasRent, v.hasForSale]))
)

// Income
const salaryRoute = new Route<ProjectContext>('salary', '/project/capacity/income/salary')
const bonusRoute = new Route<ProjectContext>('bonus', '/project/capacity/income/bonus')
const revenueRoute = new Route<ProjectContext>('revenue', '/project/capacity/income/revenue')

capacityGraph.addRoute(salaryRoute)
capacityGraph.addRoute(bonusRoute)
capacityGraph.addRoute(revenueRoute)

capacityGraph.addEdge(new Edge<ProjectContext>(expatriateRoute, salaryRoute, v.isExpatriateFilled))

capacityGraph.addEdge(
  new Edge<ProjectContext>(sectorRoute, salaryRoute, allPass([v.isNotInternational, v.isOccupationFilled]))
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled]))
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector]))
)

capacityGraph.addEdge(new Edge<ProjectContext>(bonusRoute, revenueRoute, v.isBonusFilled))

// Comortgagor Lodging
const comortgagorAddressRoute = new Route<ProjectContext>(
  'comortgagorAddress',
  '/project/capacity/lodging-comortgagor/address'
)

const comortgagorSituationRoute = new Route<ProjectContext>(
  'comortgagorSituation',
  '/project/capacity/lodging-comortgagor/situation'
)

capacityGraph.addRoute(comortgagorAddressRoute)
capacityGraph.addRoute(comortgagorSituationRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    revenueRoute,
    comortgagorAddressRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesNotLiveTogether])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(comortgagorAddressRoute, comortgagorSituationRoute, v.hasComortgagorAddress)
)

// Comortgagor Occupation
const comortgagorExpatriateRoute = new Route<ProjectContext>(
  'comortgagorExpatriate',
  '/project/capacity/occupation-comortgagor/expatriate'
)

const comortgagorSectorRoute = new Route<ProjectContext>(
  'comortgagorSector',
  '/project/capacity/occupation-comortgagor/sector'
)

capacityGraph.addRoute(comortgagorExpatriateRoute)
capacityGraph.addRoute(comortgagorSectorRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSectorRoute,
    comortgagorExpatriateRoute,
    anyPass([
      allPass([v.isInternational, v.doesLiveTogether]),
      allPass([v.doesNotLiveTogether, v.isComortgagorInternational]),
    ])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    revenueRoute,
    comortgagorSectorRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesLiveTogether])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSituationRoute,
    comortgagorSectorRoute,
    allPass([v.hasComortgagorSituationType, v.hasComortgagorRent, v.hasComortgagorForSale])
  )
)

// Comortgagor Income
const comortgagorSalaryRoute = new Route<ProjectContext>(
  'comortgagorSalary',
  '/project/capacity/income-comortgagor/salary'
)

const comortgagorBonusRoute = new Route<ProjectContext>(
  'comortgagorBonus',
  '/project/capacity/income-comortgagor/bonus'
)

const comortgagorRevenueRoute = new Route<ProjectContext>(
  'comortgagorRevenue',
  '/project/capacity/income-comortgagor/revenue'
)

capacityGraph.addRoute(comortgagorSalaryRoute)
capacityGraph.addRoute(comortgagorBonusRoute)
capacityGraph.addRoute(comortgagorRevenueRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(comortgagorExpatriateRoute, comortgagorSalaryRoute, v.isCoMortgagorExpatriateFilled)
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSectorRoute,
    comortgagorSalaryRoute,
    allPass([
      anyPass([
        allPass([v.doesLiveTogether, v.isNotInternational]),
        allPass([v.doesNotLiveTogether, v.isComortgagorNotInternational]),
      ]),
      v.isCoMortgagorOccupationFilled,
    ])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSalaryRoute,
    comortgagorBonusRoute,
    allPass([v.isCoMortgagorNotOthersSector, v.isCoMortgagorSalaryFilled])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSalaryRoute,
    comortgagorRevenueRoute,
    allPass([v.isCoMortgagorSalaryFilled, v.isCoMortgagorOthersSector])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(comortgagorBonusRoute, comortgagorRevenueRoute, v.isCoMortgagorBonusFilled)
)

// Sale
const estimatedPriceRoute = new Route<ProjectContext>('estimatedPrice', '/project/capacity/sale/estimatedPrice')
const paidOffRoute = new Route<ProjectContext>('paidOff', '/project/capacity/sale/paidOff')

const remainingMortgageRoute = new Route<ProjectContext>(
  'remainingMortgage',
  '/project/capacity/sale/remainingMortgage'
)

capacityGraph.addRoute(estimatedPriceRoute)
capacityGraph.addRoute(paidOffRoute)
capacityGraph.addRoute(remainingMortgageRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    revenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isRevenueFilled, v.isForSale, v.isSolo])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorRevenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isForSale, v.isCoMortgagorRevenueFilled])
  )
)

capacityGraph.addEdge(new Edge<ProjectContext>(estimatedPriceRoute, paidOffRoute, v.hasEstimatedPrice))
capacityGraph.addEdge(new Edge<ProjectContext>(paidOffRoute, remainingMortgageRoute, v.isNotPaidOff))

// Charges
const creditsRoute = new Route<ProjectContext>('credits', '/project/capacity/credits/credits')
const childSupportRoute = new Route<ProjectContext>('childSupport', '/project/capacity/credits/childSupport')

const comortgagorChildSupportRoute = new Route<ProjectContext>(
  'comortgagorChildSupport',
  '/project/capacity/credits/comortgagorChildSupport'
)

capacityGraph.addRoute(creditsRoute)
capacityGraph.addRoute(childSupportRoute)
capacityGraph.addRoute(comortgagorChildSupportRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(revenueRoute, creditsRoute, allPass([v.isRevenueFilled, v.isSolo, v.isNotForSale]))
)

capacityGraph.addEdge(new Edge<ProjectContext>(comortgagorRevenueRoute, creditsRoute, v.isCoMortgagorRevenueFilled))
capacityGraph.addEdge(new Edge<ProjectContext>(paidOffRoute, creditsRoute, v.isPaidOff))
capacityGraph.addEdge(new Edge<ProjectContext>(remainingMortgageRoute, creditsRoute, v.hasRemainingMortgage))
capacityGraph.addEdge(new Edge<ProjectContext>(creditsRoute, childSupportRoute, v.isCreditsFilled))

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    childSupportRoute,
    comortgagorChildSupportRoute,
    allPass([v.isNotSolo, v.isChildSupportFilled])
  )
)

// PTZ
const beenOwnerRoute = new Route<ProjectContext>('beenOwner', '/project/capacity/ptz/beenOwner')
const fiscalIncomeNm2Route = new Route<ProjectContext>('fiscalIncomeNm2', '/project/capacity/ptz/fiscalIncomeNm2')

capacityGraph.addRoute(beenOwnerRoute)
capacityGraph.addRoute(fiscalIncomeNm2Route)

capacityGraph.addEdge(
  new Edge<ProjectContext>(childSupportRoute, beenOwnerRoute, allPass([v.isSolo, v.isChildSupportFilled, v.hasPTZ]))
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorChildSupportRoute,
    beenOwnerRoute,
    allPass([v.isNotSolo, v.isCoMortgagorChildSupportFilled, v.hasPTZ])
  )
)

capacityGraph.addEdge(new Edge<ProjectContext>(beenOwnerRoute, fiscalIncomeNm2Route, v.isFirstTimeBuyer))

// Contribution
const contributionRoute = new Route<ProjectContext>('contribution', '/project/capacity/contribution/contribution')
const savingsRoute = new Route<ProjectContext>('savings', '/project/capacity/contribution/savings')

capacityGraph.addRoute(contributionRoute)
capacityGraph.addRoute(savingsRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    childSupportRoute,
    contributionRoute,
    allPass([v.isSolo, v.isChildSupportFilled, v.hasNotPTZ])
  )
)

capacityGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorChildSupportRoute,
    contributionRoute,
    allPass([v.isNotSolo, v.isCoMortgagorChildSupportFilled, v.hasNotPTZ])
  )
)

capacityGraph.addEdge(new Edge<ProjectContext>(fiscalIncomeNm2Route, contributionRoute, v.hasFiscalIncomeNM2))
capacityGraph.addEdge(new Edge<ProjectContext>(contributionRoute, savingsRoute, v.isContributionFilled))

// Pretto Search
const introductionRoute = new Route<ProjectContext>('introduction', '/project/capacity/property-search/introduction')
const localisationsRoute = new Route<ProjectContext>('localisations', '/project/capacity/property-search/localisations')
const frequencyRoute = new Route<ProjectContext>('frequency', '/project/capacity/property-search/frequency')
const criteriaEmailRoute = new Route<ProjectContext>('criteriaEmail', '/project/capacity/property-search/criteriaEmail')

capacityGraph.addRoute(introductionRoute)
capacityGraph.addRoute(localisationsRoute)
capacityGraph.addRoute(frequencyRoute)
capacityGraph.addRoute(criteriaEmailRoute)

capacityGraph.addEdge(
  new Edge<ProjectContext>(savingsRoute, introductionRoute, allPass([v.isSavingsFilled, v.isVisiblePropertySearch]))
)

capacityGraph.addEdge(new Edge<ProjectContext>(introductionRoute, localisationsRoute))
capacityGraph.addEdge(new Edge<ProjectContext>(localisationsRoute, frequencyRoute, v.hasPropertySearchLocalisations))
capacityGraph.addEdge(new Edge<ProjectContext>(frequencyRoute, criteriaEmailRoute, v.isPropertySearchAlertWanted))

// Capacity
const capacityRoute = new Route<ProjectContext>('capacity', '/capacity')

capacityGraph.addRoute(capacityRoute)

capacityGraph.addEdge(new Edge<ProjectContext>(frequencyRoute, capacityRoute, v.isPropertySearchFilled))
capacityGraph.addEdge(new Edge<ProjectContext>(criteriaEmailRoute, capacityRoute, v.isPropertySearchFilled))

capacityGraph.addEdge(
  new Edge<ProjectContext>(savingsRoute, capacityRoute, allPass([v.isSavingsFilled, v.isPropertySearchFilled]))
)
