import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { Credit, CreditIncomingPayload } from '@pretto/app/src/Sentences/types/credit'
import { CreditType, SavingsOwner } from '@pretto/app/src/Sentences/types/enums'
import { CreditKind, Owner, SavingKind } from '@pretto/app/src/types/gateway/enums'
import { Maybe } from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'
import pipe from 'ramda/src/pipe'
import { validate } from 'uuid'

import { mapMortgagors } from './mortgagors'

const mapChildren: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)
  updatedContext.children = payload.profile?.children || 0

  return {
    payload,
    context: updatedContext,
  }
}

const mapLiveTogether: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)
  if (!isNil(payload.profile?.live_together)) updatedContext.liveTogether = payload.profile?.live_together

  return {
    payload,
    context: updatedContext,
  }
}

const mapSavingsOwner = (owner?: Maybe<Owner>): SavingsOwner | undefined => {
  switch (owner) {
    case Owner.Both:
      return SavingsOwner.Both
    case Owner.Comortgagor:
      return SavingsOwner.Comortgagor
    case Owner.Mortgagor:
      return SavingsOwner.Mortgagor
    default:
      return undefined
  }
}

const mapSavings: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  const savings = payload.profile?.savings?.find(saving => saving.kind === SavingKind.CurrentAccount)
  const contribution = payload.contribution

  if (savings) {
    updatedContext.savings = contribution && savings.amount ? savings.amount - contribution : savings.amount || null
    updatedContext.savingsId = savings.id || undefined
    updatedContext.savingsOwner = mapSavingsOwner(savings.owner) || undefined
  }

  return {
    payload,
    context: updatedContext,
  }
}

const mapPTZ: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  if (!isNil(payload.profile?.first_time_buyer)) updatedContext.firstTimeBuyer = payload.profile?.first_time_buyer
  if (!isNil(payload.profile?.fiscal_income_nm2)) updatedContext.fiscalIncomeNM2 = payload.profile?.fiscal_income_nm2

  return {
    payload,
    context: updatedContext,
  }
}

const mapCreditType = (type: Maybe<CreditKind> | undefined): CreditType | null => {
  switch (type) {
    case CreditKind.Car:
      return CreditType.Car
    case CreditKind.Consumer:
      return CreditType.Consumer
    case CreditKind.Loa:
      return CreditType.LOA
    case CreditKind.Mortgage:
      return CreditType.Mortgage
    case CreditKind.Other:
      return CreditType.Other
    case CreditKind.Student:
      return CreditType.Student
    default:
      return null
  }
}

const mapCredit = (credit: CreditIncomingPayload): Credit => {
  return {
    amount: credit.payment || null,
    endDate: credit.end_date ? new Date(credit.end_date) : null,
    type: mapCreditType(credit.kind),
    id: credit.id,
  }
}

export const mapCredits: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)

  if (payload.profile?.credits) {
    updatedContext.credits = payload.profile.credits
      .filter((credit): credit is CreditIncomingPayload => !credit.bridge && validate(credit.id ?? ''))
      .map(mapCredit)
  }

  return {
    payload,
    context: updatedContext,
  }
}

export const mapProfile: ComposableMapper = pipe(
  mapChildren,
  mapLiveTogether,
  mapSavings,
  mapMortgagors,
  mapPTZ,
  mapCredits
)
