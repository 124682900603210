import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { getAuthToken } from '@pretto/app/src/Auth/lib/helpers'
import { createProjectContext } from '@pretto/app/src/Sentences/lib/factories/context'
import { getContextFromUrlParams } from '@pretto/app/src/Sentences/lib/getContextFromUrlParams'
import { getProject } from '@pretto/app/src/Sentences/lib/getProject'
import { getStoredContext } from '@pretto/app/src/Sentences/lib/localStorage'
import { mapPayloadToContext } from '@pretto/app/src/Sentences/lib/mappers/payload/mapPayloadToContext/mapPayloadToContext'
import { getSentencesContextFromLocation } from '@pretto/app/src/Sentences/lib/prefill'
import { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useStateOperations } from '@pretto/app/src/lib/state/useStateOperations'

import { createContext, PropsWithChildren, useContext, useEffect } from 'react'

// Define a unique SetStateAction with partials values from ProjectContext
// Remove the need to have a useState for each prop, keep the type
type SetContextProps = React.Dispatch<React.SetStateAction<Partial<ProjectContext>>>

// Combine both for the complete context type
export interface SentencesContextInterface extends ProjectContext {
  setContext: SetContextProps
  waitForOngoingOperations: () => Promise<ProjectContext>
}

export const SentencesContext = createContext<SentencesContextInterface>({} as SentencesContextInterface)

const getInitialContext = (projectIdUser: string): ProjectContext =>
  createProjectContext({
    ...(getStoredContext(projectIdUser) ?? {}),
    ...getSentencesContextFromLocation(),
    ...getContextFromUrlParams(),
  })

export const SentencesContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { isLoggedIn } = useAuth()

  const user = useUser()

  const projectId: string | undefined = user?.projectID

  const [context, setContext, waitForOngoingOperations] = useStateOperations<ProjectContext>(
    getInitialContext(projectId || '')
  )

  const setPartialContext: React.Dispatch<React.SetStateAction<Partial<ProjectContext>>> = state => {
    setContext(previousContext => ({
      ...previousContext,
      ...(typeof state === 'function' ? state(previousContext) : state),
    }))
  }

  const isLoading = useLoading(context.isReady !== true)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (projectId) {
        const token = getAuthToken() ?? undefined
        const data = await getProject(projectId, token)
        const project = data?.data?.project
        if (project) setContext(mapPayloadToContext(project))
      }

      setPartialContext({ isReady: true })
    })()
  }, [projectId])

  if (isLoading) {
    return null
  }

  return (
    <SentencesContext.Provider
      value={{ ...context, isLoggedIn, setContext: setPartialContext, waitForOngoingOperations }}
    >
      {children}
    </SentencesContext.Provider>
  )
}

export const useSentences = () => useContext(SentencesContext)
