import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { t } from '@pretto/app/src/lib/i18n'

export const FiscalIncomeNm2Page: React.FC = () => {
  const context = useSentences()
  const { fiscalIncomeNM2, setContext } = context

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'fiscalIncomeNm2' } }}>
      <Title>{t('sentences.ptz.title')}</Title>
      <div>
        {t('sentences.ptz.fiscalIncomeNm2.sentences.0', {
          year: (new Date().getFullYear() - 2).toString(),
        })}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'ptzFiscalIncomeNM2' } }}
          inputProps={{ autoFocus: true, placeholder: '10 000', type: 'integer' }}
          onChange={value => {
            setContext({ fiscalIncomeNM2: parseInt(value) })
          }}
          value={fiscalIncomeNM2?.toString() || ''}
        />
        {t('sentences.ptz.fiscalIncomeNm2.sentences.1')}.
      </div>
      {fiscalIncomeNM2 && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
