import { OthersPage } from '@pretto/app/src/Sentences/Introduction/Others/Containers/OthersPage'
import { PropertyTypePage } from '@pretto/app/src/Sentences/Introduction/PropertyType/Containers/PropertyTypePage'
import { PropertyUsagePage } from '@pretto/app/src/Sentences/Introduction/PropertyUsage/Containers/PropertyUsagePage'
import { SciAssociationPage } from '@pretto/app/src/Sentences/Introduction/SciAssociation/Containers/SciAssociationPage'
import { SciTypePage } from '@pretto/app/src/Sentences/Introduction/SciType/Containers/SciTypePage'
import { StructurePage } from '@pretto/app/src/Sentences/Introduction/Structure/Containers/StructurePage'
import { buyoutGraph } from '@pretto/app/src/Sentences/config/routes/buyoutGraph'
import { capacityGraph } from '@pretto/app/src/Sentences/config/routes/capacityGraph'
import { purchaseGraph } from '@pretto/app/src/Sentences/config/routes/purchaseGraph'
import { renegotiationGraph } from '@pretto/app/src/Sentences/config/routes/renegotiationGraph'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { SimulationType } from '@pretto/app/src/Sentences/types/enums'

import { Redirect, Route, Switch, useParams } from 'react-router-dom'

const getRedirect = (simulationType?: SimulationType) => {
  switch (simulationType) {
    case SimulationType.Buyout:
      return buyoutGraph.rootRoute.path

    case SimulationType.Capacity:
      return capacityGraph.rootRoute.path

    case SimulationType.Purchase:
      return purchaseGraph.rootRoute.path

    case SimulationType.Renegotiation:
      return renegotiationGraph.rootRoute.path

    default:
      return '/'
  }
}

export const IntroductionController = () => {
  const { graph } = useRoutesGraph()

  const { simulationType } = useParams<{
    simulationType?: SimulationType.Buyout | SimulationType.Capacity | SimulationType.Purchase
  }>()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/introduction/others')} component={OthersPage} />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase)/introduction/propertyType')}
        component={PropertyTypePage}
      />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/introduction/propertyUsage')}
        component={PropertyUsagePage}
      />

      <Route
        path={graph.matchRoute('/project/(capacity|purchase)/introduction/sciAssociation')}
        component={SciAssociationPage}
      />

      <Route path={graph.matchRoute('/project/(capacity|purchase)/introduction/sciType')} component={SciTypePage} />

      <Route
        path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/introduction/structure')}
        component={StructurePage}
      />

      <Redirect to={getRedirect(simulationType)} />
    </Switch>
  )
}
