import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { SimulationType, YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const PropertyPricePage: React.FC = () => {
  const context = useSentences()
  const { hasPropertyPrice, setContext } = context
  const { goToNextRoute, nextRoute } = useRoutesGraph()
  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute && !isNil(hasPropertyPrice)))

  const handlePromptClick = () => {
    setActiveField(0)
  }

  const mapHasPropertyPriceValues: MapToChoices<YesNo[]> = values =>
    values.map(value => ({
      key: value,
      value,
      isSelected:
        (value === YesNo.Yes && hasPropertyPrice) ||
        (value === YesNo.No && !hasPropertyPrice && !isNil(hasPropertyPrice)),
      onClick: () => {
        setContext({
          hasPropertyPrice: value === YesNo.Yes,
          simulationType: value === YesNo.Yes ? SimulationType.Purchase : SimulationType.Capacity,
        })
        goToNextRoute()
      },
      label: t(`sentences.maturity.propertyPrice.values.${value}`),
    }))

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'propertyPrice' } }}>
      <Title>{t('sentences.introduction.title')}</Title>
      <div>
        {t('sentences.maturity.propertyPrice.sentence')}
        <Prompt onClick={handlePromptClick} isFilled={!isNil(hasPropertyPrice)}>
          {t(
            `sentences.maturity.propertyPrice.values.${
              isNil(hasPropertyPrice) || !hasPropertyPrice ? YesNo.No : YesNo.Yes
            }`
          )}
        </Prompt>
      </div>
      {activeField === 0 && (
        <Choices
          choices={mapHasPropertyPriceValues(Object.values(YesNo))}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'hasPropertyPrice' } }}
        />
      )}
      {!isNil(hasPropertyPrice) && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
