import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { InsuranceType } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

export const PaymentPage: React.FC = () => {
  const context = useSentences()
  const {
    renegotiationPayment,
    renegotiationPaymentWithoutInsurance,
    renegotiationInsurancePayment,
    insuranceType,
    setContext,
  } = context

  const isGroupInsuranceFilled = !!renegotiationPayment && insuranceType === InsuranceType.BankGroupInsurance
  const isDelegatedInsuranceFilled =
    !!renegotiationPaymentWithoutInsurance &&
    !!renegotiationInsurancePayment &&
    insuranceType === InsuranceType.DelegatedInsurance

  const isFilled = isGroupInsuranceFilled || isDelegatedInsuranceFilled

  const [activeField, setActiveField] = useActiveField(
    renegotiationPayment || renegotiationPaymentWithoutInsurance ? 1 : 0,
    isFilled
  )

  const payment =
    insuranceType === InsuranceType.DelegatedInsurance
      ? {
          name: 'renegotiationPaymentWithoutInsurance',
          value: renegotiationPaymentWithoutInsurance,
        }
      : {
          name: 'renegotiationPayment',
          value: renegotiationPayment,
        }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'payment' } }}>
      <Title>{t('sentences.loan.title')}</Title>
      <div>
        {t(`sentences.loan.payment.sentences.${insuranceType as InsuranceType}.0`)}
        <TrackedResizableField
          clearable
          events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: payment.name } }}
          inputProps={{ autoFocus: activeField === 0, placeholder: '800', type: 'number' }}
          onChange={value => {
            setContext({ [payment.name]: parseInt(value) })
            setActiveField(1)
          }}
          onClick={() => {
            setActiveField(0)
          }}
          value={payment.value?.toString() || ''}
        />
        {t(`sentences.loan.payment.sentences.${insuranceType as InsuranceType}.1`)}
        {insuranceType === InsuranceType.DelegatedInsurance && activeField > 0 && (
          <>
            {t('sentences.loan.payment.sentences.delegatedInsurance.2')}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'renegotiationInsurancePayment' },
              }}
              inputProps={{ placeholder: '50', type: 'integer' }}
              onChange={value => {
                setContext({ renegotiationInsurancePayment: parseInt(value) })
              }}
              onClick={() => {
                setActiveField(1)
              }}
              value={renegotiationInsurancePayment?.toString() || ''}
            />
            {t('sentences.loan.payment.sentences.delegatedInsurance.3')}
          </>
        )}
        .
      </div>
      <Message>{t(`sentences.tips.renegotiationLoan`)}</Message>
      {activeField > 0 && isFilled && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
