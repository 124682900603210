import * as v from '@pretto/app/src/Sentences/lib/validators/context'
import { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import { Edge, Graph, Route } from '@pretto/app/src/lib/graph/Graph'

import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'

// Introduction
const structureRoute = new Route<ProjectContext>('structure', '/project/purchase/introduction/structure')
const propertyUsageRoute = new Route<ProjectContext>('propertyUsage', '/project/purchase/introduction/propertyUsage')
const propertyTypeRoute = new Route<ProjectContext>('propertyType', '/project/purchase/introduction/propertyType')
const sciTypeRoute = new Route<ProjectContext>('sciType', '/project/purchase/introduction/sciType')
const sciAssociationRoute = new Route<ProjectContext>('sciAssociation', '/project/purchase/introduction/sciAssociation')

export const purchaseGraph = new Graph<ProjectContext>('purchaseGraph', structureRoute)

purchaseGraph.addRoute(propertyUsageRoute)
purchaseGraph.addRoute(propertyTypeRoute)
purchaseGraph.addRoute(sciTypeRoute)
purchaseGraph.addRoute(sciAssociationRoute)

purchaseGraph.addEdge(new Edge<ProjectContext>(structureRoute, sciTypeRoute, v.isSCI))
purchaseGraph.addEdge(new Edge<ProjectContext>(sciTypeRoute, sciAssociationRoute, v.isIR))
purchaseGraph.addEdge(new Edge<ProjectContext>(sciAssociationRoute, propertyUsageRoute, v.isAssociationSimple))

purchaseGraph.addEdge(
  new Edge<ProjectContext>(structureRoute, propertyUsageRoute, allPass([v.hasStructure, v.isNotSCI]))
)

purchaseGraph.addEdge(new Edge<ProjectContext>(propertyUsageRoute, propertyTypeRoute, v.hasPropertyUsage))

// Lodging
const ageChildrenRoute = new Route<ProjectContext>('ageChildren', '/project/purchase/lodging/ageChildren')
const liveTogetherRoute = new Route<ProjectContext>('liveTogether', '/project/purchase/lodging/liveTogether')
const addressRoute = new Route<ProjectContext>('address', '/project/purchase/lodging/address')
const situationRoute = new Route<ProjectContext>('situation', '/project/purchase/lodging/situation')

purchaseGraph.addRoute(ageChildrenRoute)
purchaseGraph.addRoute(liveTogetherRoute)
purchaseGraph.addRoute(addressRoute)
purchaseGraph.addRoute(situationRoute)

purchaseGraph.addEdge(new Edge<ProjectContext>(propertyTypeRoute, ageChildrenRoute, v.hasPropertyType))

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    ageChildrenRoute,
    liveTogetherRoute,
    allPass([v.isNotSolo, v.hasAge, v.hasCoMortgagorAge, v.hasChildrenNumber])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(ageChildrenRoute, addressRoute, allPass([v.hasAge, v.hasChildrenNumber, v.isSolo]))
)

purchaseGraph.addEdge(new Edge<ProjectContext>(liveTogetherRoute, addressRoute, v.hasLiveTogetherCompleted))
purchaseGraph.addEdge(new Edge<ProjectContext>(addressRoute, situationRoute, v.hasAddress))

// Occupation
const expatriateRoute = new Route<ProjectContext>('expatriate', '/project/purchase/occupation/expatriate')
const sectorRoute = new Route<ProjectContext>('sector', '/project/purchase/occupation/sector')

purchaseGraph.addRoute(expatriateRoute)
purchaseGraph.addRoute(sectorRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(sectorRoute, expatriateRoute, allPass([v.isInternational, v.isOccupationFilled]))
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(situationRoute, sectorRoute, allPass([v.hasSituationType, v.hasRent, v.hasForSale]))
)

// Income
const salaryRoute = new Route<ProjectContext>('salary', '/project/purchase/income/salary')
const bonusRoute = new Route<ProjectContext>('bonus', '/project/purchase/income/bonus')
const revenueRoute = new Route<ProjectContext>('revenue', '/project/purchase/income/revenue')

purchaseGraph.addRoute(salaryRoute)
purchaseGraph.addRoute(bonusRoute)
purchaseGraph.addRoute(revenueRoute)

purchaseGraph.addEdge(new Edge<ProjectContext>(expatriateRoute, salaryRoute, v.isExpatriateFilled))

purchaseGraph.addEdge(
  new Edge<ProjectContext>(sectorRoute, salaryRoute, allPass([v.isNotInternational, v.isOccupationFilled]))
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(salaryRoute, bonusRoute, allPass([v.isNotOthersSector, v.isSalaryFilled]))
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(salaryRoute, revenueRoute, allPass([v.isSalaryFilled, v.isOthersSector]))
)

purchaseGraph.addEdge(new Edge<ProjectContext>(bonusRoute, revenueRoute, v.isBonusFilled))

// Comortgagor Lodging
const comortgagorAddressRoute = new Route<ProjectContext>(
  'comortgagorAddress',
  '/project/purchase/lodging-comortgagor/address'
)

const comortgagorSituationRoute = new Route<ProjectContext>(
  'comortgagorSituation',
  '/project/purchase/lodging-comortgagor/situation'
)

purchaseGraph.addRoute(comortgagorAddressRoute)
purchaseGraph.addRoute(comortgagorSituationRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    revenueRoute,
    comortgagorAddressRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesNotLiveTogether])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(comortgagorAddressRoute, comortgagorSituationRoute, v.hasComortgagorAddress)
)

// Comortgagor Occupation
const comortgagorExpatriateRoute = new Route<ProjectContext>(
  'comortgagorExpatriate',
  '/project/purchase/occupation-comortgagor/expatriate'
)

const comortgagorSectorRoute = new Route<ProjectContext>(
  'comortgagorSector',
  '/project/purchase/occupation-comortgagor/sector'
)

purchaseGraph.addRoute(comortgagorExpatriateRoute)
purchaseGraph.addRoute(comortgagorSectorRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSectorRoute,
    comortgagorExpatriateRoute,
    anyPass([
      allPass([v.isInternational, v.doesLiveTogether]),
      allPass([v.doesNotLiveTogether, v.isComortgagorInternational]),
    ])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    revenueRoute,
    comortgagorSectorRoute,
    allPass([v.isRevenueFilled, v.isNotSolo, v.doesLiveTogether])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSituationRoute,
    comortgagorSectorRoute,
    allPass([v.hasComortgagorSituationType, v.hasComortgagorRent, v.hasComortgagorForSale])
  )
)

// Comortgagor Income
const comortgagorSalaryRoute = new Route<ProjectContext>(
  'comortgagorSalary',
  '/project/purchase/income-comortgagor/salary'
)

const comortgagorBonusRoute = new Route<ProjectContext>(
  'comortgagorBonus',
  '/project/purchase/income-comortgagor/bonus'
)

const comortgagorRevenueRoute = new Route<ProjectContext>(
  'comortgagorRevenue',
  '/project/purchase/income-comortgagor/revenue'
)

purchaseGraph.addRoute(comortgagorSalaryRoute)
purchaseGraph.addRoute(comortgagorBonusRoute)
purchaseGraph.addRoute(comortgagorRevenueRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(comortgagorExpatriateRoute, comortgagorSalaryRoute, v.isCoMortgagorExpatriateFilled)
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSectorRoute,
    comortgagorSalaryRoute,
    allPass([
      anyPass([
        allPass([v.doesLiveTogether, v.isNotInternational]),
        allPass([v.doesNotLiveTogether, v.isComortgagorNotInternational]),
      ]),
      v.isCoMortgagorOccupationFilled,
    ])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSalaryRoute,
    comortgagorBonusRoute,
    allPass([v.isCoMortgagorNotOthersSector, v.isCoMortgagorSalaryFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorSalaryRoute,
    comortgagorRevenueRoute,
    allPass([v.isCoMortgagorSalaryFilled, v.isCoMortgagorOthersSector])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(comortgagorBonusRoute, comortgagorRevenueRoute, v.isCoMortgagorBonusFilled)
)

// Sale
const estimatedPriceRoute = new Route<ProjectContext>('estimatedPrice', '/project/purchase/sale/estimatedPrice')
const paidOffRoute = new Route<ProjectContext>('paidOff', '/project/purchase/sale/paidOff')

const remainingMortgageRoute = new Route<ProjectContext>(
  'remainingMortgage',
  '/project/purchase/sale/remainingMortgage'
)

purchaseGraph.addRoute(estimatedPriceRoute)
purchaseGraph.addRoute(paidOffRoute)
purchaseGraph.addRoute(remainingMortgageRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    revenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isRevenueFilled, v.isForSale, v.isSolo])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorRevenueRoute,
    estimatedPriceRoute,
    allPass([v.hasAnyForSale, v.isForSale, v.isCoMortgagorRevenueFilled])
  )
)

purchaseGraph.addEdge(new Edge<ProjectContext>(estimatedPriceRoute, paidOffRoute, v.hasEstimatedPrice))
purchaseGraph.addEdge(new Edge<ProjectContext>(paidOffRoute, remainingMortgageRoute, v.isNotPaidOff))

// Charges
const creditsRoute = new Route<ProjectContext>('credits', '/project/purchase/credits/credits')
const childSupportRoute = new Route<ProjectContext>('childSupport', '/project/purchase/credits/childSupport')

const comortgagorChildSupportRoute = new Route<ProjectContext>(
  'comortgagorChildSupport',
  '/project/purchase/credits/comortgagorChildSupport'
)

purchaseGraph.addRoute(creditsRoute)
purchaseGraph.addRoute(childSupportRoute)
purchaseGraph.addRoute(comortgagorChildSupportRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(revenueRoute, creditsRoute, allPass([v.isRevenueFilled, v.isSolo, v.isNotForSale]))
)

purchaseGraph.addEdge(new Edge<ProjectContext>(comortgagorRevenueRoute, creditsRoute, v.isCoMortgagorRevenueFilled))
purchaseGraph.addEdge(new Edge<ProjectContext>(paidOffRoute, creditsRoute, v.isPaidOff))
purchaseGraph.addEdge(new Edge<ProjectContext>(remainingMortgageRoute, creditsRoute, v.hasRemainingMortgage))
purchaseGraph.addEdge(new Edge<ProjectContext>(creditsRoute, childSupportRoute, v.isCreditsFilled))

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    childSupportRoute,
    comortgagorChildSupportRoute,
    allPass([v.isNotSolo, v.isChildSupportFilled])
  )
)

// Property
const goodRoute = new Route<ProjectContext>('good', '/project/purchase/property/good')
const constructionRoute = new Route<ProjectContext>('construction', '/project/purchase/property/construction')

const landConstructionRoute = new Route<ProjectContext>(
  'landConstruction',
  '/project/purchase/property/landConstruction'
)

const worksRoute = new Route<ProjectContext>('works', '/project/purchase/property/works')
const ecoPtzRoute = new Route<ProjectContext>('ecoPtz', '/project/purchase/property/ecoPtz')
const expectedRentalRoute = new Route<ProjectContext>('expectedRental', '/project/purchase/property/expectedRental')
const dpeRoute = new Route<ProjectContext>('dpe', '/project/purchase/property/dpe')

purchaseGraph.addRoute(goodRoute)
purchaseGraph.addRoute(constructionRoute)
purchaseGraph.addRoute(landConstructionRoute)
purchaseGraph.addRoute(dpeRoute)
purchaseGraph.addRoute(expectedRentalRoute)
purchaseGraph.addRoute(worksRoute)
purchaseGraph.addRoute(ecoPtzRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(childSupportRoute, goodRoute, allPass([v.isOldNewOrVEFA, v.isSolo, v.isChildSupportFilled]))
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorChildSupportRoute,
    goodRoute,
    allPass([v.isOldNewOrVEFA, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    childSupportRoute,
    constructionRoute,
    allPass([v.isPropertyConstruction, v.isSolo, v.isChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorChildSupportRoute,
    constructionRoute,
    allPass([v.isPropertyConstruction, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    childSupportRoute,
    landConstructionRoute,
    allPass([v.isPropertyLandConstruction, v.isSolo, v.isChildSupportFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    comortgagorChildSupportRoute,
    landConstructionRoute,
    allPass([v.isPropertyLandConstruction, v.isNotSolo, v.isCoMortgagorChildSupportFilled])
  )
)

purchaseGraph.addEdge(new Edge<ProjectContext>(goodRoute, dpeRoute, v.hasGood))

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    constructionRoute,
    expectedRentalRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.isRentalUsage])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(landConstructionRoute, expectedRentalRoute, allPass([v.hasLandPrice, v.isRentalUsage]))
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    dpeRoute,
    expectedRentalRoute,
    allPass([v.hasDpe, v.isOldNewOrVEFA, v.hasPropertyPrice, v.isRentalUsage])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    dpeRoute,
    worksRoute,
    allPass([v.hasDpe, v.isNotRentalUsage, v.hasPropertyPrice, v.isPropertyOld])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    expectedRentalRoute,
    worksRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasGood, v.hasPropertyPrice, v.isPropertyOld])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(worksRoute, ecoPtzRoute, allPass([v.isWorksFilled, v.isEligibleToEcoPtz]))
)

// PTZ
const beenOwnerRoute = new Route<ProjectContext>('beenOwner', '/project/purchase/ptz/beenOwner')
const fiscalIncomeNm2Route = new Route<ProjectContext>('fiscalIncomeNm2', '/project/purchase/ptz/fiscalIncomeNm2')

purchaseGraph.addRoute(beenOwnerRoute)
purchaseGraph.addRoute(fiscalIncomeNm2Route)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    constructionRoute,
    beenOwnerRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.hasPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    landConstructionRoute,
    beenOwnerRoute,
    allPass([v.isPropertyLandConstruction, v.hasLandPrice, v.hasPTZ])
  )
)

purchaseGraph.addEdge(new Edge<ProjectContext>(dpeRoute, beenOwnerRoute, allPass([v.hasDpe, v.hasPTZ])))

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    worksRoute,
    beenOwnerRoute,
    allPass([v.isPropertyOld, v.isWorksFilled, v.hasPTZ, v.isNotEligibleToEcoPtz])
  )
)

purchaseGraph.addEdge(new Edge<ProjectContext>(ecoPtzRoute, beenOwnerRoute, allPass([v.isEcoPtzFilled, v.hasPTZ])))

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    expectedRentalRoute,
    beenOwnerRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasRent, v.hasPTZ])
  )
)

purchaseGraph.addEdge(new Edge<ProjectContext>(beenOwnerRoute, fiscalIncomeNm2Route, v.isFirstTimeBuyer))

// Contribution
const contributionRoute = new Route<ProjectContext>('contribution', '/project/purchase/contribution/contribution')
const savingsRoute = new Route<ProjectContext>('savings', '/project/purchase/contribution/savings')

purchaseGraph.addRoute(contributionRoute)
purchaseGraph.addRoute(savingsRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    constructionRoute,
    contributionRoute,
    allPass([v.isPropertyConstruction, v.hasConstructionPrice, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    landConstructionRoute,
    contributionRoute,
    allPass([v.isPropertyLandConstruction, v.hasLandPrice, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(dpeRoute, contributionRoute, allPass([v.hasDpe, v.isPropertyNewOrVEFA, v.hasNotPTZ]))
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    expectedRentalRoute,
    contributionRoute,
    allPass([v.isExpectedRentalIncomeFilled, v.isRentalUsage, v.hasRent, v.hasNotPTZ])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    worksRoute,
    contributionRoute,
    allPass([v.isWorksFilled, v.hasNotPTZ, v.isNotEligibleToEcoPtz])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(ecoPtzRoute, contributionRoute, allPass([v.isEcoPtzFilled, v.hasNotPTZ]))
)

purchaseGraph.addEdge(new Edge<ProjectContext>(beenOwnerRoute, contributionRoute, v.isNotFirstTimeBuyer))
purchaseGraph.addEdge(new Edge<ProjectContext>(fiscalIncomeNm2Route, contributionRoute, v.hasFiscalIncomeNM2))
purchaseGraph.addEdge(new Edge<ProjectContext>(contributionRoute, savingsRoute, v.isContributionFilled))

// Competition
const hasCompetitionRoute = new Route<ProjectContext>('hasCompetition', '/project/purchase/competition/hasCompetition')
const offerRoute = new Route<ProjectContext>('offer', '/project/purchase/competition/offer')

purchaseGraph.addRoute(hasCompetitionRoute)
purchaseGraph.addRoute(offerRoute)

purchaseGraph.addEdge(new Edge<ProjectContext>(savingsRoute, hasCompetitionRoute, allPass([v.isSavingsFilled])))
purchaseGraph.addEdge(new Edge<ProjectContext>(hasCompetitionRoute, offerRoute, v.hasCompetition))

// Rate alert
const wantsRateAlertRoute = new Route<ProjectContext>('wantsRateAlert', '/project/purchase/rate-alert/wantsRateAlert')

purchaseGraph.addRoute(wantsRateAlertRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    hasCompetitionRoute,
    wantsRateAlertRoute,
    allPass([v.isCompetitionFilled, v.hasNotCompetition, v.isVisibleRateAlert])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    offerRoute,
    wantsRateAlertRoute,
    allPass([v.hasCompetitionBank, v.hasCompetitionRate, v.hasCompetitionDuration, v.isVisibleRateAlert])
  )
)

// Simulation
const simulationRoute = new Route<ProjectContext>('simulation', '/simulation')

purchaseGraph.addRoute(simulationRoute)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    hasCompetitionRoute,
    simulationRoute,
    allPass([v.isCompetitionFilled, v.hasNotCompetition, v.isRateAlertFilled])
  )
)

purchaseGraph.addEdge(
  new Edge<ProjectContext>(
    offerRoute,
    simulationRoute,
    allPass([v.hasCompetitionBank, v.hasCompetitionRate, v.hasCompetitionDuration, v.isRateAlertFilled])
  )
)

purchaseGraph.addEdge(new Edge<ProjectContext>(wantsRateAlertRoute, simulationRoute, v.isRateAlertFilled))
