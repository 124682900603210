import { g } from '@pretto/bricks/components/layout'

import { RevenueBox } from '@pretto/app/src/Sentences/ComortgagorIncome/Revenue/components/RevenueBox'
import { DividerWithMarginBottom } from '@pretto/app/src/Sentences/components/Styled/Divider/Divider'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedButtonInline } from '@pretto/app/src/Sentences/containers/TrackedButtonInline/TrackedButtonInline'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { createRevenue } from '@pretto/app/src/Sentences/lib/factories/revenue'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import type { Revenue } from '@pretto/app/src/Sentences/types/compensation'
import { RevenueType } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import findLast from 'lodash/findLast'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const Description = styled.p`
  margin-bottom: ${g(2)};
`

const Box = styled(RevenueBox)`
  & + & {
    margin-top: ${g(2)};
  }
`

export const RevenuePage: React.FC = () => {
  const context = useSentences()
  const { setContext, mortgagors } = context
  const { nextRoute } = useRoutesGraph()

  const [activeField, setActiveField] = useActiveField(0, Boolean(nextRoute))
  const [editableId, setEditableId] = useState<string | null>(null)

  useEffect(() => {
    const lastEmpty = findLast(
      mortgagors?.[1].revenues || [],
      revenue => revenue.amount === null && revenue.type === null
    )

    if (lastEmpty) setEditableId(lastEmpty.id)
  }, [mortgagors])

  if (!mortgagors || !mortgagors.length) {
    return null
  }

  const descriptionString = [
    t('sentences.income.revenue.values.rent'),
    t('sentences.income.revenue.values.childSupport'),
    t('sentences.income.revenue.values.professionalBenefits'),
    t('sentences.income.revenue.values.familyAllowances'),
    t('sentences.income.revenue.values.expatriationBonus'),
  ].join(', ')

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'revenue-comortgagor' } }}>
      <Title>{t('sentences.income.comortgagor.revenue.title')}</Title>
      <Description>({descriptionString}).</Description>
      {!!mortgagors?.[1]?.revenues &&
        mortgagors[1].revenues
          .filter(revenue => !revenue._delete)
          .map((revenue, index) => {
            const setRevenue = (updatedRevenue: Revenue) => {
              setContext(({ mortgagors }) => ({
                mortgagors:
                  mortgagors?.map(mortgagor => {
                    if (mortgagors[1].id === mortgagor.id) {
                      return {
                        ...mortgagor,
                        revenues:
                          mortgagor.revenues?.map(currentRevenue => {
                            if (currentRevenue.id === revenue.id) {
                              return updatedRevenue
                            }

                            return currentRevenue
                          }) ?? [],
                      }
                    }

                    return mortgagor
                  }) ?? [],
              }))
            }

            const choices = Object.values(RevenueType).map(value => {
              return {
                key: value,
                isSelected: revenue.type === value,
                onClick: () => {
                  setRevenue({ ...revenue, type: value })
                },
                label: t(`sentences.income.revenue.values.${value}`),
              }
            })

            const handleChangeAmount = (value: string) => {
              setRevenue({ ...revenue, amount: parseInt(value) })
            }

            return (
              <Box
                key={revenue.id}
                activeField={activeField}
                index={index}
                isEditable={editableId === revenue.id}
                onClick={() => {
                  setEditableId(revenue.id)
                }}
                onRemove={() => {
                  setRevenue({ id: revenue.id, _delete: true })
                }}
                onPromptClick={() => {
                  setActiveField(0)
                }}
                onChoiceClick={() => {
                  setActiveField(1)
                }}
                onChangeAmount={handleChangeAmount}
                revenue={revenue}
                choices={choices}
              />
            )
          })}
      <DividerWithMarginBottom />
      <TrackedButtonInline
        events={{
          eventName: EventName.SimulationElementClicked,
          eventPayload: { element: 'coMortgagorRevenueAddValue' },
        }}
        onClick={() => {
          setActiveField(0)
          // trouver un moyen de faire ouvrir le bon editableIndex
          setContext({
            mortgagors: mortgagors.map(mortgagor => {
              if (mortgagor.id === mortgagors[1].id) {
                return {
                  ...mortgagor,
                  revenues: [...(mortgagor.revenues ?? []), createRevenue()],
                }
              }

              return mortgagor
            }),
          })
        }}
      >
        {t('sentences.income.revenue.addValue')}
      </TrackedButtonInline>
      <ButtonNext>{t(mortgagors?.[1].revenues?.length ? 'sentences.next' : 'sentences.skip')}</ButtonNext>
    </TrackedView>
  )
}
