import { toShortISO } from '@pretto/app/src/Sentences/lib/date'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import { SimulationType } from '@pretto/app/src/Sentences/types/enums'
import type { Project, Renegotiation } from '@pretto/app/src/types/gateway/schema'

export const mapRenegotiation: ComposableMapper = ({ context, payload }) => {
  // Always clone to prevent mutation
  // structuredClone is available in all modern browsers
  const updatedPayload: Project = structuredClone(payload)
  const clonedRenegociation: Renegotiation = payload.renegotiation ? structuredClone(payload.renegotiation) : {}

  if (context.bank) clonedRenegociation.bank = context.bank
  if (context.endYear) {
    const date = new Date()
    date.setFullYear(context.endYear)
    clonedRenegociation.end_date = toShortISO(date as Date)
  }
  if (context.estimatedValue) clonedRenegociation.estimated_value = context.estimatedValue
  if (context.rate) clonedRenegociation.rate = context.rate
  if (context.remainingPrincipal) clonedRenegociation.remaining_principal = context.remainingPrincipal

  if (context.simulationType === SimulationType.Renegotiation) updatedPayload.renegotiation = clonedRenegociation

  return {
    context,
    payload: updatedPayload,
  }
}
