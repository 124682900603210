import { BeenOwnerPage } from '@pretto/app/src/Sentences/Ptz/BeenOwner/Containers/BeenOwnerPage'
import { FiscalIncomeNm2Page } from '@pretto/app/src/Sentences/Ptz/FiscalIncomeNm2/Containers/FiscalIncomeNm2Page'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const PtzController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/(capacity|purchase)/ptz/beenOwner')} component={BeenOwnerPage} />

      <Route
        path={graph.matchRoute('/project/(capacity|purchase)/ptz/fiscalIncomeNm2')}
        component={FiscalIncomeNm2Page}
      />

      <Redirect to="/" />
    </Switch>
  )
}
