import { createProjectContext } from '@pretto/app/src/Sentences/lib/factories/context'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import type { Project } from '@pretto/app/src/types/gateway/schema'

import pipe from 'ramda/src/pipe'

import { mapBridge } from './bridge'
import { mapCompetition } from './competition'
import { mapContribution } from './contribution'
import { mapMaturity } from './maturity'
import { mapProfile } from './profile'
import { mapProject } from './project'
import { mapRenegotiation } from './renegotiation'
import { mapStructure } from './structure'

const createContext: ComposableMapper = ({ payload }) => ({
  payload,
  context: createProjectContext(),
})

const mapId: ComposableMapper = ({ context, payload }) => {
  const updatedContext: ProjectContext = structuredClone(context)
  updatedContext.id = payload.id || null

  return {
    payload,
    context: updatedContext,
  }
}

export const mapPayloadToContext = (payload: Project): ProjectContext =>
  pipe(
    createContext,
    mapContribution,
    mapId,
    mapMaturity,
    mapProfile,
    mapProject,
    mapStructure,
    mapRenegotiation,
    mapBridge,
    mapCompetition
  )({ payload, context: {} as ProjectContext }).context
