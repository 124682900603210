import type { Income } from '@pretto/app/src/Sentences/types/compensation'
import { IncomeType } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { t } from '@pretto/app/src/lib/i18n'

export const mapBonusTypeValues: MapToChoices<IncomeType[]> = (values, context) => {
  if (!context) return []
  const { mortgagors, setContext } = context
  const clonedMortgagors = structuredClone(mortgagors) ?? [{}]

  return values.map(value => ({
    key: value,
    isSelected: mortgagors?.[0].bonus?.type === value,
    onClick: () => {
      clonedMortgagors[0].bonus = {
        ...clonedMortgagors[0].bonus,
        type: value,
      } as Income
      setContext({ mortgagors: clonedMortgagors })
    },
    label: t(`sentences.income.bonus.type.${value}`),
  }))
}
