import { toShortISO } from '@pretto/app/src/Sentences/lib/date'
import type { ComposableMapper } from '@pretto/app/src/Sentences/lib/mappers/payload/types/mapper'
import type { ProjectContext } from '@pretto/app/src/Sentences/types/context'
import type { Deletable } from '@pretto/app/src/Sentences/types/deletable'
import {
  OccupationContractType,
  OccupationOthersStatus,
  OccupationPublicStatus,
  OccupationType,
  SciType,
  SituationType,
  Structure,
} from '@pretto/app/src/Sentences/types/enums'
import type { Mortgagor as ContextMortgagor } from '@pretto/app/src/Sentences/types/mortgagor'
import { Contract, Housing, MortgageArrangement } from '@pretto/app/src/types/gateway/enums'
import type {
  Job,
  Localisation,
  Mortgagor as PayloadMortgagor,
  Profile,
  Project,
} from '@pretto/app/src/types/gateway/schema'

import isNil from 'lodash/isNil'

import { mapBonus, mapIncome, mapRevenues } from './incomes'

const createMortgagor = (mortgagor: Partial<PayloadMortgagor>): PayloadMortgagor =>
  ({
    ...mortgagor,
    // Use as PayloadMortgagor for expatriate_status not needed if deleting element
  }) as PayloadMortgagor

const isMetropolitan = (country: string): boolean => country === 'fr'

const mapAddress = (
  contextMortgagor: ContextMortgagor,
  context: ProjectContext,
  payloadMortgagors: PayloadMortgagor[]
): Localisation | null | undefined => {
  if (context.liveTogether && payloadMortgagors.length > 0) {
    return payloadMortgagors[0].address
  }

  const { country, zipcode, city } = contextMortgagor

  if (!country || !isMetropolitan(country))
    return {
      country,
    }

  return {
    country,
    city,
    zipcode,
  }
}

const mapContract = (mortgagor: ContextMortgagor): Contract | undefined => {
  switch (true) {
    case mortgagor.occupationType === OccupationType.Others &&
      mortgagor.status === OccupationOthersStatus.BusinessOwner:
      return Contract.BusinessOwner
    case mortgagor.occupationType === OccupationType.Others &&
      mortgagor.status === OccupationOthersStatus.CasualShowBusinessWorker:
      return Contract.CasualShowBusinessWorker
    case mortgagor.occupationType === OccupationType.Public &&
      mortgagor.status === OccupationPublicStatus.Contractor &&
      mortgagor.contractType === OccupationContractType.ShortTerm:
      return Contract.ContractuelCdd
    case mortgagor.occupationType === OccupationType.Public &&
      mortgagor.status === OccupationPublicStatus.Contractor &&
      mortgagor.contractType === OccupationContractType.LongTerm:
      return Contract.ContractuelCdi
    case mortgagor.occupationType === OccupationType.Public && mortgagor.status === OccupationPublicStatus.Tenure:
      return Contract.Tenure
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Craftsman:
      return Contract.Craftsman
    case mortgagor.occupationType === OccupationType.Medical:
      return Contract.Doctor
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Farmer:
      return Contract.Farmer
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Interim:
      return Contract.Interim
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Jobless:
      return Contract.Jobless
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Jobseeker:
      return Contract.Jobseeker
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Lawyer:
      return Contract.Lawyer
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Liberal:
      return Contract.Liberal
    case mortgagor.occupationType === OccupationType.Others &&
      mortgagor.status === OccupationOthersStatus.ParentalLeave:
      return Contract.ParentalLeave
    case mortgagor.occupationType === OccupationType.Private &&
      mortgagor.contractType === OccupationContractType.LongTerm &&
      mortgagor.executiveRole &&
      !mortgagor.trialPeriod:
      return Contract.PermanentExecutive
    case mortgagor.occupationType === OccupationType.Private &&
      mortgagor.contractType === OccupationContractType.LongTerm &&
      mortgagor.executiveRole &&
      mortgagor.trialPeriod:
      return Contract.PermanentExecutiveTrial
    case mortgagor.occupationType === OccupationType.Private &&
      mortgagor.contractType === OccupationContractType.LongTerm &&
      !mortgagor.executiveRole &&
      !mortgagor.trialPeriod:
      return Contract.PermanentNonExecutive
    case mortgagor.occupationType === OccupationType.Private &&
      mortgagor.contractType === OccupationContractType.LongTerm &&
      !mortgagor.executiveRole &&
      mortgagor.trialPeriod:
      return Contract.PermanentNonExecutiveTrial
    case mortgagor.occupationType === OccupationType.Private &&
      mortgagor.contractType === OccupationContractType.ShortTerm:
      return Contract.FixedTerm
    case mortgagor.occupationType === OccupationType.Others &&
      mortgagor.status === OccupationOthersStatus.PortageSalarial:
      return Contract.PortageSalarial
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Retired:
      return Contract.Retired
    case mortgagor.occupationType === OccupationType.Others &&
      mortgagor.status === OccupationOthersStatus.SalariedDirector:
      return Contract.SalariedDirector
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.SelfEmployed:
      return Contract.SelfEmployed
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Student:
      return Contract.Student
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status === OccupationOthersStatus.Trader:
      return Contract.Trader
    case mortgagor.occupationType === OccupationType.Public && mortgagor.status === OccupationPublicStatus.Intern:
      return Contract.Internship
    // Seems to never happens
    // case false:
    //   return Contract.PermanentUnspecified
    // return Contract.Childminder
    // return Contract.Military
    default:
      return undefined
  }
}

const mapHousing = (
  situationType: SituationType,
  context: ProjectContext,
  payloadMortgagors: PayloadMortgagor[]
): Housing | null | undefined => {
  if (context.liveTogether && payloadMortgagors.length > 0) {
    return payloadMortgagors[0].housing
  }

  switch (situationType) {
    case SituationType.Tenant:
      return Housing.Tenant
    case SituationType.Owner:
      return Housing.Owner
    case SituationType.Sheltered:
      return Housing.Sheltered
    case SituationType.StaffHoused:
      return Housing.StaffHoused
    default:
      return Housing.Unspecified
  }
}

const mapJob = (mortgagor: ContextMortgagor): Job | undefined => {
  if (!mortgagor.startDate) {
    return undefined
  }

  switch (true) {
    case mortgagor.occupationType === OccupationType.Public &&
      mortgagor.status === OccupationPublicStatus.Contractor &&
      mortgagor.contractType === OccupationContractType.ShortTerm:
    case mortgagor.occupationType === OccupationType.Private &&
      mortgagor.contractType === OccupationContractType.ShortTerm:
    case mortgagor.occupationType === OccupationType.Others && mortgagor.status !== OccupationOthersStatus.Retired:
    case mortgagor.occupationType === OccupationType.Medical:
      return {
        start_date: toShortISO(mortgagor.startDate),
      }

    default:
      return {
        start_date: null,
      }
  }
}

const mapRent = (
  mortgagor: ContextMortgagor,
  context: ProjectContext,
  payloadMortgagors: PayloadMortgagor[]
): number | null | undefined => {
  if (!mortgagor.rent) {
    return undefined
  }

  if (context.liveTogether && payloadMortgagors.length > 0) {
    return null
  }

  switch (mortgagor.situationType) {
    case SituationType.Tenant:
    case SituationType.StaffHoused:
      return mortgagor.rent

    default:
      return null
  }
}

const mapChildSupport = (mortgagor: ContextMortgagor): number | null | undefined => {
  if (isNil(mortgagor.childSupport)) {
    return undefined
  }

  if (!mortgagor.paysChildSupport || mortgagor.childSupport === 0) {
    return null
  }

  return mortgagor.childSupport
}

const mapMortgagor = (
  contextMortgagor: ContextMortgagor,
  context: ProjectContext,
  payloadMortgagors: PayloadMortgagor[]
): PayloadMortgagor | Deletable<PayloadMortgagor> => {
  if (contextMortgagor._delete) {
    return {
      id: contextMortgagor.id,
      _delete: contextMortgagor._delete,
    }
  }

  const payloadMortgagor: PayloadMortgagor = {
    expatriate_status: contextMortgagor.expatriate ?? false,
  }

  if (contextMortgagor.id) payloadMortgagor.id = contextMortgagor.id
  if (contextMortgagor.income) payloadMortgagor.salary = mapIncome(contextMortgagor.income)
  if (contextMortgagor.age) payloadMortgagor.age = contextMortgagor.age
  if (contextMortgagor.birthdate) payloadMortgagor.birthdate = toShortISO(contextMortgagor.birthdate)
  if (contextMortgagor.country) payloadMortgagor.address = mapAddress(contextMortgagor, context, payloadMortgagors)
  if (!isNil(contextMortgagor.rent)) payloadMortgagor.rent = mapRent(contextMortgagor, context, payloadMortgagors)
  if (
    contextMortgagor.contractType ||
    contextMortgagor.status ||
    contextMortgagor.occupationType === OccupationType.Medical
  )
    payloadMortgagor.contract = mapContract(contextMortgagor)
  if (contextMortgagor.situationType)
    payloadMortgagor.housing = mapHousing(contextMortgagor.situationType, context, payloadMortgagors)
  if (contextMortgagor.startDate) payloadMortgagor.job = mapJob(contextMortgagor)
  if (contextMortgagor.bonus) payloadMortgagor.bonus = mapBonus(contextMortgagor)
  if (contextMortgagor.revenues) payloadMortgagor.incomes = mapRevenues(contextMortgagor.revenues)
  if (!isNil(contextMortgagor.childSupport)) payloadMortgagor.child_support = mapChildSupport(contextMortgagor)

  return payloadMortgagor
}

const mapMortgagorsArrangement = (context: ProjectContext): MortgageArrangement | null | undefined => {
  if (context.structure !== Structure.SCI) {
    return null
  }

  switch (context.sciType) {
    case SciType.IS:
      return MortgageArrangement.SciIs
    case SciType.IR:
      return MortgageArrangement.SciIr
    default:
      return undefined
  }
}

const isPlaceholder = (mortgagor: ContextMortgagor): boolean =>
  Object.values(mortgagor).every(
    value => value === null || value === undefined || (Array.isArray(value) && value.length === 0)
  )

export const mapMortgagors: ComposableMapper = ({ context, payload }) => {
  const updatedPayload: Project = structuredClone(payload)
  const profile: Profile = structuredClone(updatedPayload.profile) ?? {}
  profile.mortgagors =
    context.mortgagors
      ?.filter(mortgagor => !isPlaceholder(mortgagor))
      .reduce<PayloadMortgagor[]>(
        (previous, mortgagor) => [...previous, createMortgagor(mapMortgagor(mortgagor, context, previous))],
        []
      ) || []

  if (context.structure) updatedPayload.mortgagors_arrangement = mapMortgagorsArrangement(context)

  updatedPayload.profile = profile

  return {
    context,
    payload: updatedPayload,
  }
}
