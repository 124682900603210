import type { TrackingProps } from '@pretto/app/src/Sentences/lib/tracking/types/event'
import { useTracking } from '@pretto/app/src/Sentences/lib/tracking/useTracking'

import { useEffect } from 'react'

export const TrackedView: React.FC<TrackingProps> = ({ children, defaultEventTrackingType, events }) => {
  const trackActions = useTracking(defaultEventTrackingType)

  useEffect(() => {
    trackActions(events)
  }, [events])

  return <>{children}</>
}
