import { defaultEventsTrackingType, Event } from '@pretto/app/src/Sentences/config/tracking/events'
import { useExperiments } from '@pretto/app/src/Sentences/contexts/ExperimentsContext'
import { trackEventBackend } from '@pretto/app/src/Sentences/lib/tracking/trackEventBackend'
import { EventPayload, EventTrackingType } from '@pretto/app/src/Sentences/lib/tracking/types/event'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { debugTracking, isDebugMode, useTracking as useDefaultTracking } from '@pretto/app/src/lib/tracking'

export const useTracking = (defaultEventTrackingType?: EventTrackingType) => {
  const trackEventDefault = useDefaultTracking()

  const { experiments } = useExperiments()

  const { projectID } = useUser()

  const trackAction = ({ eventName, eventPayload = {}, eventTrackingType }: Event) => {
    const payload: EventPayload = {
      ...eventPayload,
      ...experiments,
      context: 'sentences',
    }

    const trackingType =
      eventTrackingType ??
      defaultEventTrackingType ??
      defaultEventsTrackingType[eventName] ??
      EventTrackingType.FRONTEND

    if (isDebugMode) {
      return debugTracking('sentences', eventName, payload)
    }

    switch (trackingType) {
      case EventTrackingType.NONE:
        break

      case EventTrackingType.ALL:
        trackEventBackend(eventName, projectID, payload)
        trackEventDefault(eventName, payload)
        break

      case EventTrackingType.BACKEND:
        trackEventBackend(eventName, projectID, payload)
        break

      case EventTrackingType.SEGMENT:
        trackEventDefault(eventName, payload)
        break

      case EventTrackingType.FRONTEND:
      default:
        trackEventDefault(eventName, payload)
        break
    }
  }

  const trackActions = (events: Event | Event[]) => {
    const eventsArray = events instanceof Array ? events : [events]

    eventsArray.forEach(event => trackAction(event))
  }

  return trackActions
}
