import { AddressPage } from '@pretto/app/src/Sentences/ComortgagorLodging/Address/Containers/AddressPage'
import { SituationPage } from '@pretto/app/src/Sentences/ComortgagorLodging/Situation/Containers/SituationPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const ComortgagorLodgingController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route
        path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/lodging-comortgagor/situation')}
        component={SituationPage}
      />

      <Route
        path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/lodging-comortgagor/address')}
        component={AddressPage}
      />

      <Redirect to="/" />
    </Switch>
  )
}
