import { temporal } from '@pretto/bricks/core/utility/temporal'

import {
  isDateInRangeFromToday,
  TimeUnit,
} from '@pretto/app/src/Sentences/Maturity/Default/lib/isDateInRangeFromToday'
import { t } from '@pretto/app/src/lib/i18n'

type DateStatusResult = {
  state: 'default' | 'error' | 'warning'
  message: string | null
}

const MAX_CREDIBLE_DATE_RANGE = { range: 1, unit: TimeUnit.YEAR }
const NB_CARATERES_IN_FULL_DATE = 14

export const getDateStatus = (date: Date | null | undefined, inputValue: string): DateStatusResult => {
  if (!date) {
    return { state: 'default', message: '' }
  }

  if (!temporal(date).isValid()) {
    return { state: 'error', message: t('sentences.invalidDate') }
  }

  if (inputValue.length === NB_CARATERES_IN_FULL_DATE) {
    if (!isDateInRangeFromToday({ date, ...MAX_CREDIBLE_DATE_RANGE })) {
      return { state: 'warning', message: t('sentences.suspiciousDate') }
    }

    return { state: 'default', message: null }
  }

  return { state: 'default', message: '' }
}
