import { ConstructionPage } from '@pretto/app/src/Sentences/Property/Construction/Containers/ConstructionPage'
import { dpePage } from '@pretto/app/src/Sentences/Property/DPE/Containers/DPE'
import { EcoPtzPage } from '@pretto/app/src/Sentences/Property/EcoPtz/Containers/EcoPtzPage'
import { ExpectedRentalPage } from '@pretto/app/src/Sentences/Property/ExpectedRental/Containers/ExpectedRentalPage'
import { GoodPage } from '@pretto/app/src/Sentences/Property/Good/Containers/GoodPage'
import { LandConstructionPage } from '@pretto/app/src/Sentences/Property/LandConstruction/Containers/LandConstructionPage'
import { WorksPage } from '@pretto/app/src/Sentences/Property/Works/Containers/WorksPage'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'

import { Redirect, Route, Switch } from 'react-router-dom'

export const PropertyController = () => {
  const { graph } = useRoutesGraph()

  return (
    <Switch>
      <Route path={graph.matchRoute('/project/purchase/property/good')} component={GoodPage} />
      <Route path={graph.matchRoute('/project/purchase/property/construction')} component={ConstructionPage} />
      <Route path={graph.matchRoute('/project/purchase/property/landConstruction')} component={LandConstructionPage} />
      <Route path={graph.matchRoute('/project/purchase/property/works')} component={WorksPage} />
      <Route path={graph.matchRoute('/project/purchase/property/expectedRental')} component={ExpectedRentalPage} />
      <Route path={graph.matchRoute('/project/purchase/property/dpe')} component={dpePage} />
      <Route path={graph.matchRoute('/project/purchase/property/ecoPtz')} component={EcoPtzPage} />
      <Redirect to="/" />
    </Switch>
  )
}
