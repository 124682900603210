import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { Months, OccupationContractType, OccupationPublicStatus } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'

import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

interface PublicSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PublicSentence: React.FC<PublicSentenceProps> = ({ activeField, onSetActive }) => {
  const context = useSentences()
  const { mortgagors, setContext } = context
  const comortgagor = mortgagors?.[1] ?? {}
  const [debouncedStartYear] = useDebounce(comortgagor.startYear, 1000)
  const clonedMortgagors = structuredClone(mortgagors)

  if (!clonedMortgagors) {
    return null
  }

  useEffect(() => {
    if (!Object.values(OccupationPublicStatus).includes(comortgagor.status as OccupationPublicStatus)) {
      clonedMortgagors[1].status = undefined
    }
    clonedMortgagors[1].trialPeriod = undefined
    clonedMortgagors[1].executiveRole = undefined
    setContext({ mortgagors: clonedMortgagors })
  }, [])

  const handleYearChange = (value: string) => {
    const year = parseInt(value)
    const date = comortgagor.startDate || new Date()
    date.setFullYear(year)
    clonedMortgagors[1].startDate = date
    clonedMortgagors[1].startYear = value
    setContext({ mortgagors: clonedMortgagors })
  }

  return (
    <>
      {t('sentences.situation.public.sentence')}
      <Prompt
        onClick={() => {
          onSetActive(1)
        }}
        isFilled={!!comortgagor.status}
      >
        {t(
          `sentences.situation.public.values.${
            (comortgagor.status as OccupationPublicStatus) || OccupationPublicStatus.Tenure
          }`
        )}
      </Prompt>
      {(activeField > 1 || comortgagor.startDate) && comortgagor.status === OccupationPublicStatus.Contractor && (
        <>
          {t('sentences.situation.in')}
          <Prompt
            onClick={() => {
              onSetActive(2)
            }}
            isFilled={!!comortgagor.contractType}
          >
            {t(
              `sentences.situation.contractType.values.${comortgagor.contractType || OccupationContractType.LongTerm}`
            )}
          </Prompt>
        </>
      )}
      {(activeField > 2 || comortgagor.startDate) &&
        comortgagor.status === OccupationPublicStatus.Contractor &&
        comortgagor.contractType === OccupationContractType.ShortTerm && (
          <>
            {t('sentences.situation.since')}
            <Prompt isFilled={!!comortgagor.startMonth}>
              {t(`sentences.months.${(comortgagor.startMonth as Months) || '0'}`)}
            </Prompt>
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorOccupationPublicSince' },
              }}
              inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(context), type: 'date' }}
              message={t('sentences.situation.error')}
              onChange={handleYearChange}
              state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
              value={comortgagor.startYear || ''}
            />
          </>
        )}
    </>
  )
}
