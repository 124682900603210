import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { ButtonBack as ButtonBackComponent } from '@pretto/zen/reveal/atoms/buttons/buttonBack/ButtonBack'

import Front from '@pretto/app/src/Helpers/frontchat'
import { BuyoutController } from '@pretto/app/src/Sentences/Buyout'
import { ChargeController } from '@pretto/app/src/Sentences/Charge'
import { ComortgagorIncomeController } from '@pretto/app/src/Sentences/ComortgagorIncome'
import { ComortgagorLodgingController } from '@pretto/app/src/Sentences/ComortgagorLodging'
import { ComortgagorOccupationController } from '@pretto/app/src/Sentences/ComortgagorOccupation'
import { CompetitionController } from '@pretto/app/src/Sentences/Competition'
import { ContributionController } from '@pretto/app/src/Sentences/Contribution'
import { IncomeController } from '@pretto/app/src/Sentences/Income'
import { IntroductionController } from '@pretto/app/src/Sentences/Introduction'
import { LoanController } from '@pretto/app/src/Sentences/Loan'
import { LodgingController } from '@pretto/app/src/Sentences/Lodging'
import { MaturityController } from '@pretto/app/src/Sentences/Maturity'
import { IndexPage } from '@pretto/app/src/Sentences/Maturity/Index/Containers/IndexPage'
import { OccupationController } from '@pretto/app/src/Sentences/Occupation'
import { PropertyController } from '@pretto/app/src/Sentences/Property'
import { PropertyRenegotiationController } from '@pretto/app/src/Sentences/PropertyRenegotiation'
import { PropertySearchController } from '@pretto/app/src/Sentences/PropertySearch'
import { PtzController } from '@pretto/app/src/Sentences/Ptz'
import { RateAlertController } from '@pretto/app/src/Sentences/RateAlert'
import { SaleController } from '@pretto/app/src/Sentences/Sale'
import { SentencesHeader } from '@pretto/app/src/Sentences/components/Header'
import { LocalStorage } from '@pretto/app/src/Sentences/components/LocalStorage'
import { Navbar as NavbarComponent } from '@pretto/app/src/Sentences/components/Navbar/Navbar'
import { Timeline as TimelineComponent } from '@pretto/app/src/Sentences/components/Timeline'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ErrorsContextProvider } from '@pretto/app/src/Sentences/contexts/ErrorsContext'
import { ExperimentsContextProvider } from '@pretto/app/src/Sentences/contexts/ExperimentsContext'
import { RoutesGraphContextProvider, useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { SentencesContextProvider, useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { TimelineContextProvider } from '@pretto/app/src/Sentences/contexts/TimelineContext'
import { useTracking } from '@pretto/app/src/Sentences/lib/tracking/useTracking'
import { t } from '@pretto/app/src/lib/i18n'

import 'core-js/actual/structured-clone'
import { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

// TODO: Remove it when ok
Front('kill')

const SentencesController: React.FC = () => {
  const { graph, previousRoute } = useRoutesGraph()

  const { simulationType } = useSentences()

  const trackActions = useTracking()

  useEffect(() => {
    if (simulationType) {
      trackActions({ eventName: EventName.SimulationStarted, eventPayload: { simulationType } })
    }
  }, [simulationType])

  return (
    <>
      <LocalStorage />

      <SentencesHeader />

      <Page>
        <TimelineContextProvider>
          <Responsive max="laptop">
            <Navbar />
            <Timeline />
          </Responsive>

          <Responsive min="laptop">
            <Sidebar>
              {previousRoute?.path && <ButtonBack href={previousRoute.path}>{t('sentences.back')}</ButtonBack>}
              <Timeline />
            </Sidebar>
          </Responsive>
        </TimelineContextProvider>

        <Content>
          <Switch>
            <Route path={graph.matchRoute('/project/maturity')} component={MaturityController} />

            <Route
              path={graph.matchRoute('/project/:simulationType(buyout|capacity|purchase|renegotiation)?/introduction')}
              component={IntroductionController}
            />

            <Route
              path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/lodging')}
              component={LodgingController}
            />

            <Route
              path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/occupation')}
              component={OccupationController}
            />

            <Route
              path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/income')}
              component={IncomeController}
            />

            <Route
              path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/lodging-comortgagor')}
              component={ComortgagorLodgingController}
            />

            <Route
              path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/occupation-comortgagor')}
              component={ComortgagorOccupationController}
            />

            <Route
              path={graph.matchRoute('/project/(capacity|purchase|renegotiation)/income-comortgagor')}
              component={ComortgagorIncomeController}
            />

            <Route path={graph.matchRoute('/project/(capacity|purchase)/sale')} component={SaleController} />

            <Route
              path={graph.matchRoute('/project/(buyout|capacity|purchase|renegotiation)/credits')}
              component={ChargeController}
            />

            <Route path={graph.matchRoute('/project/purchase/property')} component={PropertyController} />
            <Route path={graph.matchRoute('/project/(capacity|purchase)/ptz')} component={PtzController} />

            <Route
              path={graph.matchRoute('/project/(buyout|capacity|purchase)/contribution')}
              component={ContributionController}
            />

            <Route path={graph.matchRoute('/project/capacity/property-search')} component={PropertySearchController} />
            <Route path={graph.matchRoute('/project/purchase/competition')} component={CompetitionController} />

            <Route
              path={graph.matchRoute('/project/(purchase|renegotiation)/rate-alert')}
              component={RateAlertController}
            />

            <Route path={graph.matchRoute('/project/buyout/buyout')} component={BuyoutController} />
            <Route path={graph.matchRoute('/project/renegotiation/loan')} component={LoanController} />

            <Route
              path={graph.matchRoute('/project/renegotiation/property-renegotiation')}
              component={PropertyRenegotiationController}
            />

            <Redirect exact path="/project" to="/project/maturity" />
            <Redirect to="/" />
          </Switch>
        </Content>
      </Page>
    </>
  )
}

export const SentencesModule: React.FC = () => {
  return (
    <ThemeProvider designSystem="reveal">
      <ErrorsContextProvider>
        <ExperimentsContextProvider>
          <SentencesContextProvider>
            <RoutesGraphContextProvider>
              <Switch>
                <Route exact path="/" component={IndexPage} />
                <Route path="/project" component={SentencesController} />
                <Redirect to="/" />
              </Switch>
            </RoutesGraphContextProvider>
          </SentencesContextProvider>
        </ExperimentsContextProvider>
      </ErrorsContextProvider>
    </ThemeProvider>
  )
}

const Page = styled.div`
  ${grid()};
  height: 100dvh;
  grid-template-rows: auto 1fr;

  @media only screen and (min-width: ${breakpoints.laptop}) {
    height: 100%;
    grid-template-rows: 1fr;
  }
`

const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral5};

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([1, 4])};
    ${grid(3, { isRightAligned: true })};
    grid-template-rows: auto 1fr;
    grid-row-gap: ${g(3)};
    padding-top: ${g(3)};
  }
`

const ButtonBack = styled(ButtonBackComponent)`
  ${column([2, 3])};
  justify-self: flex-start;
`

const Timeline = styled(TimelineComponent)`
  ${column([2, 3])};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100dvh - ${g(7)});
  ${column([2, 4])};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(3)};
    ${column([6, 7])};
    height: 100%;
  }
`

const Navbar = styled(NavbarComponent)`
  ${column([2, 4])};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`
