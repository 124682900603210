import { LOCAL_STORAGE_ALERT } from '@pretto/app/src/Sentences/config/storage/storage'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { MapToChoices } from '@pretto/app/src/Sentences/types/mapToChoices'
import { setItem } from '@pretto/app/src/config/itemStorage'
import { t } from '@pretto/app/src/lib/i18n'

import isNil from 'lodash/isNil'

export const mapWantsRateAlertValues: MapToChoices<YesNo[]> = (values, context) => {
  if (!context) return []
  const { wantsRateAlert, setContext } = context

  return values.map(value => ({
    key: value,
    isSelected:
      !isNil(wantsRateAlert) && ((wantsRateAlert && value === YesNo.Yes) || (!wantsRateAlert && value === YesNo.No)),
    onClick: () => {
      if (value === YesNo.No) setItem(LOCAL_STORAGE_ALERT, 'true')
      setContext({ wantsRateAlert: value === YesNo.Yes })
    },
    label: t(`sentences.rateAlert.wantsRateAlert.values.${value}`),
  }))
}
